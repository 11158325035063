import React, { ReactNode, MouseEvent, useMemo, createElement } from 'react'
import classNames from 'classnames'

import styles from './bottom_appbar.scss'

interface ButtonField {
  text: ReactNode
  onClick?: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  theme?: 'default' | 'karrot'
  href?: string
  disabled?: boolean
}

export interface BottomAppBarProps {
  /**
   * 버튼들에 대한 옵션
   */
  buttons: ButtonField[]
  /**
   * 하단에 fixed 로 붙힐지
   */
  sticky?: boolean
  /**
   * 버튼 좌측에 위치시킬 엘리먼트 ex. 하트버튼
   */
  appendLeft?: ReactNode
  /**
   * 버튼 우측에 위치시킬 엘리먼트 ex. 하트버튼
   */
  appendRight?: ReactNode
  /**
   * 최상위 엘리먼트에 부여할 className
   */
  className?: string
  /**
   * 애니메이션 제거 여부
   */
  ignoreAnimation?: boolean
  /**
   * 우선순위 가장 높음
   */
  children?: ReactNode
}

const BottomAppBar = ({
  buttons = [],
  sticky = true,
  appendLeft,
  appendRight,
  className,
  ignoreAnimation,
  children,
}: BottomAppBarProps) => {
  const buttonInstance = useMemo(
    () =>
      buttons.map(({ theme = 'default', text, onClick, href, disabled }, idx) => {
        return (
          <div key={idx} className={styles.appbarActionButtonWrapper}>
            {createElement(
              href ? 'a' : 'button',
              {
                className: classNames(styles.actionButton, {
                  [styles.karrotTheme]: theme === 'karrot',
                  [styles.defaultTheme]: theme === 'default',
                  [styles.disabled]: disabled,
                }),
                href,
                onClick,
                disabled,
              },
              text
            )}
          </div>
        )
      }),
    [buttons]
  )

  const childrenInstance = (
    <div className={styles.appbarWrapper}>
      {appendLeft}
      <div className={styles.appbarActionsWrapper}>{children || buttonInstance}</div>
      {appendRight}
    </div>
  )

  return sticky ? (
    <div
      className={classNames(styles.bottomAppbar, className, {
        [styles.ignoreAnimation]: ignoreAnimation,
      })}>
      {childrenInstance}
    </div>
  ) : (
    <div className={className}>{childrenInstance}</div>
  )
}

export default BottomAppBar
