import React, { ReactNode, MouseEvent } from 'react'
import classNames from 'classnames'

import Modal, { ModalProps } from '../Modal'
import styles from './action_modal.scss'

interface ButtonField {
  text: ReactNode
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  theme?: 'default' | 'karrot'
  disabled?: boolean
}

export interface ActionModalProps extends Omit<ModalProps, 'isCenterMode' | 'children'> {
  /**
   * 액션 모달의 텍스트 영역
   */
  message: ReactNode
  /**
   * 액션 모달의 액션 리스트
   */
  actions: ButtonField[]
  /**
   * 액션들의 정렬 방식
   */
  actionsDirection?: 'row' | 'column'
  /**
   * children 이 있을 경우에는 다른 렌더링 하는 모든 요소를 제거합니다. 로딩같은 표현을 할 때 제외하고는 사용하지 말아주세요.
   */
  children?: ReactNode
}

const ActionModal: React.FC<ActionModalProps> = ({
  isOpen,
  onClose,
  message,
  children,
  actions,
  actionsDirection = 'row',
  ...modalProps
}) => {
  return (
    <Modal
      {...modalProps}
      isOpen={isOpen}
      onClose={onClose}
      bodyClassName={classNames(styles.actionModalBodyOverrideStyle, classNames)}>
      <div className={styles.actionModalCard}>
        {children || (
          <>
            <div className={styles.actionModalCardBody}>{message}</div>
            <div className={styles.actionModalCardActionsWrapper} style={{ flexDirection: actionsDirection }}>
              {actions.map(({ theme = 'default', text, onClick, disabled }, idx) => (
                <div className={styles.actionModalCardActionWrapper} key={idx}>
                  <button
                    className={classNames(styles.actionModalCardAction, {
                      [styles.whiteButton]: theme === 'default',
                      [styles.karrotButton]: theme === 'karrot',
                    })}
                    disabled={disabled}
                    onClick={onClick}>
                    {text}
                  </button>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default ActionModal
