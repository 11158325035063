import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

import ProgressIndicator from '@src/components/svg/ProgressIndicator'
import { LOADER_SPIN_SPEED } from '@src/constants/styles'

import { FixedFlexContainer } from './Container'

const Loader: React.FC = () => {
  return (
    <AdjustedFixedFlexContainer>
      <AnimationProgressIndicator />
    </AdjustedFixedFlexContainer>
  )
}

export default Loader

const spin = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
`

const AnimationProgressIndicator = styled(ProgressIndicator)`
  animation: ${spin} ${LOADER_SPIN_SPEED} infinite linear;
`

const AdjustedFixedFlexContainer = styled(FixedFlexContainer)`
  top: 54px;
`
