import { map, catchError } from 'rxjs/operators'

import { throwApiError } from '@src/utils'
import { RegionInfo } from '@src/types/common'

import Request from './request'

export const API_NAMESPACE = 'hoian'
export const PATH_GET_USER_INFO = '/api/v23/users'
export const PATH_REQ_PHONE_CODE = '/api/v23/users/phone_verification_code_for_update.json'
export const PATH_UPDATE_PHONE_NUMBER = '/api/v23/users/update_phone_number.json'
export const PATH_GET_AD_ALLOWED = '/api/v23/users/me/personalized_advertising.json'
export const PATH_UPDATE_AD_ALLOWED = '/api/v23//users/me/personalized_advertising.json'
export const PATH_UPDATE_WEB_CRAWL_ALLOWED = '/api/v23/users/user_extend.json'
export const PATH_GET_ARTICLE_REGIONS = '/api/v23/articles/article_regions.json'
export const PATH_GET_CHANGEABLE_REGIONS = PATH_GET_USER_INFO
export const PATH_UPDATE_ARTICLES_REGION = '/api/v23/articles/update_articles_region.json'

interface MessageResponse {
  status?: {
    code: string
    message: string | null
    type?: string
  }
}
class Hoian extends Request {
  constructor(baseUrl: string, baseUrlKey: string) {
    super(baseUrl, baseUrlKey)

    this.setUserAgentHeader()
    this.setTokenHeader()
    this.setDeviceIdentityHeader()
  }

  public getMyAccountUserInfo$(userId: string, isEmailInclude: boolean) {
    let include = 'identity_verification'

    if (isEmailInclude) {
      include += ',email'
    }

    return this.get$(`${PATH_GET_USER_INFO}/${userId}.json`, {
      include,
    }).pipe(
      map(({ data }) => data),
      catchError((err) => throwApiError({ err, origin: API_NAMESPACE, api: 'getMyAccountUserInfo$' }))
    )
  }

  public getAdAllowed$() {
    return this.get$(PATH_GET_AD_ALLOWED).pipe(
      map(({ data }) => data),
      catchError((err) => throwApiError({ err, origin: API_NAMESPACE, api: 'getAdAllowed$' }))
    )
  }

  public getOtherSettingsUserInfo$(userId: string) {
    return this.get$(`${PATH_GET_USER_INFO}/${userId}.json`, {
      include: 'user_extend',
    }).pipe(
      map(({ data }) => data),
      catchError((err) => throwApiError({ err, origin: API_NAMESPACE, api: 'getOtherSettingsUserInfo$' }))
    )
  }

  public reqPhoneCode$(phone: string) {
    return this.put$<MessageResponse>(PATH_REQ_PHONE_CODE, { phone_number: phone }).pipe(
      map(({ data }) => data),
      catchError((err) => throwApiError({ err, origin: API_NAMESPACE, api: 'reqPhoneCode$' }))
    )
  }

  public updatePhoneNumber$(phone: string, code: string) {
    return this.put$<MessageResponse>(PATH_UPDATE_PHONE_NUMBER, { new_phone_number: phone, code }).pipe(
      map(({ data }) => data),
      catchError((err) => throwApiError({ err, origin: API_NAMESPACE, api: 'updatePhoneNumber$' }))
    )
  }

  public updateWebCrawlAllowed$(webCrawlAllowed: boolean) {
    return this.put$(PATH_UPDATE_WEB_CRAWL_ALLOWED, { web_crawl_allowed: webCrawlAllowed }).pipe(
      map(({ data }) => data),
      catchError((err) => throwApiError({ err, origin: API_NAMESPACE, api: 'updateWebCrawlAllowed$' }))
    )
  }

  public updateAdAllowed$(adAllowed: boolean) {
    return this.put$(PATH_UPDATE_AD_ALLOWED, { enabled: adAllowed }).pipe(
      map(({ data }) => data),
      catchError((err) => throwApiError({ err, origin: API_NAMESPACE, api: 'updateWebCrawlAllowed$' }))
    )
  }

  public getArticleRegions$() {
    return this.get$(PATH_GET_ARTICLE_REGIONS).pipe(
      map(({ data }) => data),
      catchError((err) => throwApiError({ err, origin: API_NAMESPACE, api: 'getArticleRegions$' }))
    )
  }

  public getChangeableRegions$(userId: string) {
    return this.get$(`${PATH_GET_CHANGEABLE_REGIONS}/${userId}.json`, {
      include: 'user_extend, checkin_counts',
    }).pipe(
      map(({ data }) => data),
      catchError((err) => throwApiError({ err, origin: API_NAMESPACE, api: 'getChangeableRegions$' }))
    )
  }

  public updateArticlesRegion$(selectedArticleRegion: RegionInfo, selectedCheckinRegion: RegionInfo) {
    const isH3User = !!selectedArticleRegion.locationNameId && !!selectedCheckinRegion.locationNameId

    const queryParams = isH3User
      ? {
          before_region_id: selectedArticleRegion.regionId,
          before_location_name_id: selectedArticleRegion.locationNameId,
          after_location_name_id: selectedCheckinRegion.locationNameId,
        }
      : {
          before_region_id: selectedArticleRegion.regionId,
          after_region_id: selectedCheckinRegion.regionId,
        }

    return this.put$(PATH_UPDATE_ARTICLES_REGION, queryParams).pipe(
      map(({ data }) => data),
      catchError((err) => throwApiError({ err, origin: API_NAMESPACE, api: 'getChangeableRegions$' }))
    )
  }
}

export default Hoian
