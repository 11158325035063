import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { AppScreen } from '@stackflow/plugin-basic-ui'

import { MenuContainer, PageContainer } from '@src/components/base/Container'
import { MenuTitle, MenuSubtitle } from '@src/components/base/Title'
import Switch from '@src/components/base/Switch'
import { adAllowedSelector, webCrawlAllowedSelector } from '@src/selectors/user'
import { reqUpdateWebCrawlAllowed, initStateFromOtherSettings, reqUpdateAdAllowed } from '@src/ducks/user'
import { useDispatchWithHistory } from '@src/hooks/useDispatchWithHistory'
import { useFlow } from '@src/router/Routes'
import IconBack from '@src/components/svg/IconBack'
import { SCHEME_PREFIX } from '@src/config'

const OtherSettingsPage: React.FC = () => {
  const dispatch = useDispatchWithHistory()
  const { t } = useTranslation()
  const { push } = useFlow()
  const webCrawlAllowed = useSelector(webCrawlAllowedSelector)
  const adAllowed = useSelector(adAllowedSelector)

  useEffect(() => {
    dispatch(initStateFromOtherSettings())
  }, [dispatch])

  const handleWebCrawlAllowedChange = (checked: boolean) => {
    dispatch(reqUpdateWebCrawlAllowed({ webCrawlAllowed: checked }))
  }
  const handleAdAllowedChange = (checked: boolean) => {
    dispatch(reqUpdateAdAllowed({ adAllowed: checked }))
  }

  return (
    <AppScreen
      appBar={{
        title: t('other_settings.title'),
        backButton: {
          renderIcon: () => <IconBack />,
          onClick: () => {},
        },
      }}>
      <PageContainer>
        <MenuContainerWithSwitch>
          <MenuContainer>
            <MenuTitle>{t('other_settings.index.menu.search_engine_acceptance.title')}</MenuTitle>
            <MenuSubtitle>
              {t(
                `other_settings.index.menu.search_engine_acceptance.subtitle_${
                  webCrawlAllowed ? 'accepted' : 'rejected'
                }`
              )}
            </MenuSubtitle>
          </MenuContainer>
          <SwitchWrapper>
            <Switch onChange={handleWebCrawlAllowedChange} checked={webCrawlAllowed} />
          </SwitchWrapper>
        </MenuContainerWithSwitch>
        <MenuContainerWithSwitch>
          <MenuContainer>
            <MenuTitle>{t('other_settings.index.menu.personal_advertisement_acceptance.title')}</MenuTitle>
            <MenuSubtitle>
              {t(
                `other_settings.index.menu.personal_advertisement_acceptance.subtitle_${
                  adAllowed ? 'accepted' : 'rejected'
                }`
              )}
            </MenuSubtitle>
          </MenuContainer>
          <SwitchWrapper>
            <Switch onChange={handleAdAllowedChange} checked={adAllowed} />
          </SwitchWrapper>
        </MenuContainerWithSwitch>
        <MenuContainer>
          <MenuButton
            onClick={() => {
              window.location.href = `${SCHEME_PREFIX}://my_category_setting`
            }}>
            <MenuTitle>{t('other_settings.index.menu.fleamarket_category_setting.title')}</MenuTitle>
          </MenuButton>
        </MenuContainer>
      </PageContainer>
    </AppScreen>
  )
}

export default OtherSettingsPage

const MenuContainerWithSwitch = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`

const SwitchWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 12px;
  padding-right: 16px;
`

const MenuButton = styled.button`
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0 0 20px 0;
  border: 0;
  background: transparent;
  font: inherit;
  color: inherit;
`
