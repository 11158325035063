import { PHONE_NUMBER_MASK } from '@src/constants/regex'

export const formatPhoneNumber = (phoneNumber: string, countryCode: keyof typeof PHONE_NUMBER_MASK) => {
  if (!phoneNumber) {
    return phoneNumber
  }

  const normalizedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '')
  const mask = PHONE_NUMBER_MASK[countryCode]
  let result = ''
  let currentIndex = 0

  for (let i = 0; currentIndex < normalizedPhoneNumber.length && i < mask.length; i++) {
    if (mask[i] === 'X') {
      result += normalizedPhoneNumber[currentIndex] ?? ''
      currentIndex++
    } else {
      result += mask[i] ?? ''
    }
  }

  return result
}

export const revertPhoneNumber = (formattedPhoneNumber: string) => {
  return formattedPhoneNumber.replace(/(\s*)/g, '')
}

export const formatAcceptLanguage = (acceptLanguage: string) => {
  return acceptLanguage.split('_').join('-')
}

export const formatVerifiedAt = (verifiedAt: string) => {
  const verifiedDate = new Date(verifiedAt)
  return `${verifiedDate.getFullYear()}.${verifiedDate.getMonth() + 1}.${verifiedDate.getDate()}`
}
