import { useState, useMemo, useCallback } from 'react'
import { AnyAction, ActionCreator } from 'redux'
import { useSelector } from 'react-redux'

import { asyncState as AsyncState } from '@src/ducks/asyncState'
import { RootState } from '@src/ducks'

export const useActionState = <Action extends AnyAction>(
  action: ActionCreator<Action>
): [typeof action, ReturnType<typeof AsyncState>] => {
  const { type } = useMemo(() => action({}), [action])
  const [payload, setPayload] = useState({})

  const normalizedID = useMemo(() => `${type}::${JSON.stringify(payload)}`, [type, payload])
  const asyncState = useSelector(({ asyncState: async }: RootState) => async[normalizedID] || AsyncState())

  const _action = useCallback(
    (...arg: Parameters<typeof action>) => {
      const act = action(...arg)
      setPayload(act.payload || {})

      return act
    },
    [action]
  )

  return [_action, asyncState]
}
