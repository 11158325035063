import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { vars } from '@seed-design/design-token'
import { AppScreen } from '@stackflow/plugin-basic-ui'
import { useActivityParams } from '@stackflow/react'
import { AlertDialog, DialogContainer } from '@daangn/sprout-components-dialog'
import { parseAppInfoSafe } from '@daangn/webview-ua-parser'
import { openExternalBrowser } from '@daangn/webview-link-router'

import { PageContainer } from '@src/components/base/Container'
import { formatVerifiedAt } from '@src/utils'
import IconBack from '@src/components/svg/IconBack'
import { IS_PROD } from '@src/config'

import type { IdentityVerification } from './MyAccountPage'

const appInfo = parseAppInfoSafe(window.navigator.userAgent)

const osTypeLowerCase = appInfo?.osType.toLowerCase() || 'desktop'

const isAvailableToMoveStore = () => {
  if (!appInfo) return false

  return (
    (osTypeLowerCase === 'ios' && parseInt(appInfo.versionCode, 10) >= 230900) ||
    (osTypeLowerCase === 'android' && parseInt(appInfo.versionCode, 10) >= 231800)
  )
}

const IdentityVerificationMarketPage: React.FC = () => {
  const { identification: serializedIdentification } = useActivityParams<{
    identification: string
  }>()

  const getIdentification = (): IdentityVerification | null => {
    try {
      return JSON.parse(serializedIdentification)
    } catch (e) {
      console.error(e)
      return null
    }
  }

  const { t } = useTranslation()

  function formatPhoneNumber() {
    const identification = getIdentification()
    if (identification) {
      const phoneNumber = identification.phone_number_e164
      const localNumber = phoneNumber.replace('+82', '0').trim();
      const part1 = localNumber.substring(3, 5) + '**';
      const part2 = localNumber.substring(7, 9) + '**';
      return `${localNumber.substring(0, 3)}-${part1}-${part2}`;
    }
    return null
  }

  function formatBirthday() {
    const identification = getIdentification()
    if (identification) {
      return `${identification.birth_date.year.toString().substring(0, 3)}*. ${identification.birth_date.month}. ${identification.birth_date.day.toString().substring(0, 1)}*`
    }
    return null
  }

  const [shouldDialogueOpen, setShouldDialogueOpen] = useState(false)

  const handleFaq = () => {
    try {
      if (!appInfo || parseInt(appInfo.versionCode, 10) < 234600) {
        setShouldDialogueOpen(true)
        return
      }

      window.location.href = IS_PROD
        ? 'karrot://identification?type=account&referrer=settings_webview'
        : 'karrot.alpha://identification?type=account&referrer=settings_webview'
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <AppScreen
      appBar={{
        backButton: {
          renderIcon: () => <IconBack />,
          onClick: () => {},
        },
      }}>
      <PageContainer>
        <Container>
          <TitleMessage>{t('my_account.identity_verification.title')}</TitleMessage>
          <dl>
            <MemuContainer>
              <MenuTitle>{t('my_account.identity_verification.menu.verified_at')}</MenuTitle>
              <MenuContent>
                {getIdentification() ? formatVerifiedAt(getIdentification()!.verify_time) : null}
              </MenuContent>
            </MemuContainer>
            <MemuContainer>
              <MenuTitle>{t('my_account.identity_verification.menu.phone_number')}</MenuTitle>
              <MenuContent>{formatPhoneNumber()}</MenuContent>
            </MemuContainer>
            <MemuContainer>
              <MenuTitle>{t('my_account.identity_verification.menu.birthday')}</MenuTitle>
              <MenuContent>{formatBirthday()}</MenuContent>
            </MemuContainer>
          </dl>
          <FaqLink onClick={handleFaq}>본인인증 정보 수정하기</FaqLink>
        </Container>
        <DialogContainer
          onOutsideClick={() => {
            setShouldDialogueOpen(false)
          }}>
          {shouldDialogueOpen &&
            (isAvailableToMoveStore() ? (
              <AlertDialog
                description="스토어에 앱을 업데이트 해주세요"
                onPrimaryAction={() => {
                  if (osTypeLowerCase === 'ios') {
                    openExternalBrowser({
                      url: 'https://apps.apple.com/app/id1018769995',
                      stage: 'production',
                    })
                    setShouldDialogueOpen(false)
                    return
                  }

                  if (osTypeLowerCase === 'android') {
                    window.location.href = 'https://play.google.com/store/apps/details?id=com.towneers.www'
                    openExternalBrowser({
                      url: 'https://play.google.com/store/apps/details?id=com.towneers.www',
                      stage: 'production',
                    })
                    setShouldDialogueOpen(false)
                    return
                  }
                }}
                onSecondaryAction={() => {
                  setShouldDialogueOpen(false)
                }}
                primaryActionLabel="스토어 가기"
                secondaryActionIntent="neutral"
                secondaryActionLabel="다음에 하기"
                title="새로운 버전이 필요해요"
                maxWidth="80%"
                UNSAFE_style={{ whiteSpace: 'pre-line' }}
              />
            ) : (
              <AlertDialog
                description="스토어에서 앱을 업데이트 해주세요"
                onPrimaryAction={() => {
                  setShouldDialogueOpen(false)
                }}
                primaryActionLabel="확인"
                title="새로운 버전이 필요해요"
                maxWidth="80%"
                UNSAFE_style={{ whiteSpace: 'pre-line' }}
              />
            ))}
        </DialogContainer>
      </PageContainer>
    </AppScreen>
  )
}

export default IdentityVerificationMarketPage

const Container = styled.div`
  padding: 8px 16px 16px;
`

const TitleMessage = styled.h2`
  font-size: 1.25rem;
  color: ${vars.$scale.color.gray900};
  margin-bottom: 32px;
`

const MemuContainer = styled.div`
  margin-bottom: 24px;
`

const MenuTitle = styled.dt`
  font-weight: bold;
  font-size: 0.875rem;
  margin-bottom: 4px;
`

const MenuContent = styled.dd`
  font-size: 0.875rem;
`

const FaqLink = styled.p`
  color: ${vars.$scale.color.gray600};
  font-size: 0.875rem;
  text-decoration: underline;
  padding-top: 8px;
`
