import React, { memo } from 'react'

const ArrowRight: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57576 5.07575C8.81007 4.84144 9.18997 4.84144 9.42429 5.07575L15.9243 11.5758C16.1586 11.8101 16.1586 12.19 15.9243 12.4243L9.42429 18.9243C9.18998 19.1586 8.81008 19.1586 8.57576 18.9243C8.34145 18.69 8.34145 18.3101 8.57576 18.0758L14.6515 12L8.57576 5.92428C8.34145 5.68997 8.34145 5.31007 8.57576 5.07575Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default memo(ArrowRight)
