import { combineEpics } from 'redux-observable'
import { filter, catchError, mergeMap } from 'rxjs/operators'

import {
  reqArticleRegions,
  resArticleRegions,
  reqUpdateArticlesRegion,
  resUpdateArticlesRegion,
} from '@src/ducks/article'
import { Epic } from '@src/types/epic'
import { asyncMap } from '@src/utils'
import { reportError } from '@src/ducks/report'
import { bridge } from '@src/bridge'
import { RegionInfo } from '@src/types/common'

export const reqArticleRegions$$: Epic = (action$, _, { hoian }) =>
  action$.pipe(
    filter(reqArticleRegions.match),
    asyncMap(() => {
      return hoian.getArticleRegions$().pipe(
        mergeMap((data) => {
          if (!data) {
            new Error('articleRegions is not exist on hoian.getArticleRegions$')
          }

          if (data.status && data.status.code !== 'ok') {
            return [resArticleRegions({ articleRegions: [] }), reportError(new Error(data.status.message))]
          }

          const isH3User = Array.isArray(data) && data.length > 0 && !!data[0].locationName && !!data[0].locationNameId

          // H3 User 응답에는 locationName, locationNameId가 추가로 내려와요.
          if (isH3User) {
            const articleLocations: RegionInfo[] = data.map((item: RegionInfo) => ({
              displayName: `${item.locationName} (${item.regionName})`,
              regionId: item.regionId,
              regionName: item.regionName,
              locationName: item.locationName,
              locationNameId: item.locationNameId,
              count: item.articleCount,
            }))

            return [resArticleRegions({ articleRegions: articleLocations })]
          }

          // Region User 응답에는 locationName, locationNameId가 없어요.
          return [
            resArticleRegions({
              articleRegions: data.map((item: RegionInfo) => ({
                displayName: item.regionName,
                regionId: item.regionId,
                regionName: item.regionName,
                locationName: null,
                locationNameId: null,
                count: item.count,
              })),
            }),
          ]
        }),
        catchError((err) => [reportError(err)])
      )
    })
  )

export const reqUpdateArticlesRegion$$: Epic = (action$, _, { hoian, t }) =>
  action$.pipe(
    filter(reqUpdateArticlesRegion.match),
    asyncMap(({ payload: { selectedArticleRegion, selectedCheckinRegion, history } }) => {
      return hoian.updateArticlesRegion$(selectedArticleRegion, selectedCheckinRegion).pipe(
        mergeMap((data) => {
          const statusCode = data?.status?.code

          if (statusCode === 'ok') {
            bridge.openToast({ toast: { body: t('other_settings.change_articles_region.message.update_done') } })
            history.pop()

            return [resUpdateArticlesRegion({ message: null })]
          } else {
            const message = data.status.message
            bridge.openToast({ toast: { body: message } })

            // cannot_change_region_in_neighborhood_ads 코드가 넘어오면 에러 리포트를 하지 않고 토스트만 띄움
            if (statusCode === 'cannot_change_region_in_neighborhood_ads') {
              return [resUpdateArticlesRegion({ message })]
            } else {
              history.pop()
              return [resUpdateArticlesRegion({ message }), reportError(new Error(message))]
            }
          }
        }),
        catchError((err) => [reportError(err)])
      )
    })
  )

export default combineEpics(reqArticleRegions$$, reqUpdateArticlesRegion$$)
