import { createAction, createReducer } from '@reduxjs/toolkit'

import { createHistoryAction } from '@src/utils/action'

export const reqEmailCode = createAction<{ email: string }>('REQ_EMAIL_CODE')
export const resEmailCode = createAction<{ message: string | null }>('RES_EMAIL_CODE')
export const reqUpdateEmail = createHistoryAction<{ email: string; code: string }>('REQ_UPDATE_EMAIL')
export const resUpdateEmail = createAction<{ message: string | null }>('RES_UPDATE_EMAIL')
export const resetEmailState = createAction('RESET_EMAIL_STATE')

export interface State {
  emailCodeMessage: string | null
  isCodeSent: boolean
  updateEmailMessage: string | null
}

export const INITIAL_STATE: State = {
  emailCodeMessage: null,
  isCodeSent: false,
  updateEmailMessage: null,
}

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(reqEmailCode, (state) => {
      state.emailCodeMessage = null
    })
    .addCase(resEmailCode, (state, { payload }) => {
      state.emailCodeMessage = payload.message

      if (payload.message === null) {
        state.isCodeSent = true
      }
    })
    .addCase(reqUpdateEmail, (state) => {
      state.updateEmailMessage = null
    })
    .addCase(resUpdateEmail, (state, { payload }) => {
      state.updateEmailMessage = payload.message
    })
    .addCase(resetEmailState, (state) => {
      state.isCodeSent = false
      state.emailCodeMessage = null
      state.updateEmailMessage = null
    })
})
