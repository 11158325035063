// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes kf-3OugA{0%{opacity:0}100%{opacity:1}}@keyframes kf-10cys{0%{opacity:1}100%{opacity:0}}.kf-2d-n5{top:0;bottom:0;left:0;right:0;width:100%;height:100%}.kf-2dpdH{position:fixed;overflow:hidden;animation-fill-mode:forwards;animation-timing-function:cubic-bezier(0.23, 1, 0.32, 1)}.kf-2dpdH *{box-sizing:border-box}.kf-2dpdH.kf-1cT1V{animation-name:kf-3OugA}.kf-2dpdH.kf-1F8zi{animation-name:kf-10cys}.kf-2dpdH.kf-3IfvJ{display:flex;justify-content:center;align-items:center}.kf-3Mu9J{background-color:rgba(0,0,0,0.5);position:absolute}.kf-2PEOo{max-height:100%;max-width:100%;position:absolute}.kf-2PEOo.kf-3IfvJ{position:relative}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullScreen": "kf-2d-n5",
	"modalBase": "kf-2dpdH",
	"open": "kf-1cT1V",
	"easeIn": "kf-3OugA",
	"close": "kf-1F8zi",
	"easeOut": "kf-10cys",
	"centerMode": "kf-3IfvJ",
	"modalOverlay": "kf-3Mu9J",
	"modalCard": "kf-2PEOo"
};
export default ___CSS_LOADER_EXPORT___;
