import { css } from '@emotion/react'

// body
export const body1 = css`
  font-size: 16px;
  font-weight: normal;
  line-height: 150%;
  letter-spacing: -0.02em;
`
export const body2 = css`
  font-size: 14px;
  font-weight: normal;
  line-height: 150%;
  letter-spacing: -0.02em;
`

// title
export const title1 = css`
  font-size: 24px;
  font-weight: bold;
  line-height: 125%;
  letter-spacing: -0.03em;
`
export const title2 = css`
  font-size: 20px;
  font-weight: bold;
  line-height: 125%;
  letter-spacing: -0.03em;
`
export const title3 = css`
  font-size: 18px;
  font-weight: bold;
  line-height: 135%;
  letter-spacing: -0.03em;
`
export const navTitle1 = css`
  font-size: 19px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: -0.03em;
`

// subtitle
export const subtitle1 = css`
  font-size: 16px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: -0.02em;
`
export const subtitle2 = css`
  font-size: 14px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: -0.02em;
`

// caption
export const caption1 = css`
  font-size: 13px;
  font-weight: normal;
  line-height: 150%;
  letter-spacing: -0.02em;
`
export const caption2 = css`
  font-size: 12px;
  font-weight: normal;
  line-height: 150%;
  letter-spacing: -0.02em;
`
