import { createAction } from '@reduxjs/toolkit'

export const createHistoryAction = <P, T extends string = string>(name: T) => {
  return createAction<
    P & {
      history: { pop: () => void; push: () => void }
      isRoot: boolean
    }
  >(name)
}
