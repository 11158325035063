import { HOIAN_KEY, DEFAULT_COUNTRY_KEY, KARROTAUTH_KEY } from '@src/constants/api'
import { API_URLS } from '@src/config'

import Storage from './storage'
import Hoian from './hoian'
import Karrotauth from './karrotauth'

const urls = API_URLS[DEFAULT_COUNTRY_KEY]

export const storage = new Storage()
export const hoian = new Hoian(urls[HOIAN_KEY], HOIAN_KEY)
export const karrotauth = new Karrotauth(urls[KARROTAUTH_KEY], KARROTAUTH_KEY)

export const setApisCountry = (countryKey?: string) => {
  if (!countryKey) return
  if (DEFAULT_COUNTRY_KEY === countryKey) {
    return
  }

  hoian.setBaseUrl(countryKey)
  karrotauth.setBaseUrl(countryKey)
}

export const setStorageData = (accessToken?: string, userAgent?: string, acceptLanguage?: string, deviceIdentity?: string) => {
  accessToken && storage.setToken(accessToken)
  userAgent && storage.setUserAgent(userAgent)
  acceptLanguage && storage.setAcceptLanguage(acceptLanguage)
  deviceIdentity && storage.setDeviceIdentity(deviceIdentity)
}
