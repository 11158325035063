// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes kf-3q8Nv{0%{transform:scale(0.05)}10%{transform:scale(0.1)}20%{transform:scale(0.2)}30%{transform:scale(0.3)}40%{transform:scale(0.4)}50%{transform:scale(1.1)}60%{transform:scale(1.2)}70%{transform:scale(1.3)}80%{transform:scale(1.2)}90%{transform:scale(1.1)}100%{transform:scale(1)}}.kf-2V7Tf{padding:1rem;display:block;background:none;border:none}.kf-2V7Tf.kf-10Hlr{animation:kf-3q8Nv 200ms ease-in}.kf-2V7Tf svg path{transition:fill 150ms}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heartButton": "kf-2V7Tf",
	"filled": "kf-10Hlr",
	"fully": "kf-3q8Nv"
};
export default ___CSS_LOADER_EXPORT___;
