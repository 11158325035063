import { useState, useCallback } from 'react'

// For making ErrorBoundary get asyncError
// https://github.com/facebook/react/issues/14981#issuecomment-468460187
export const useAsyncError = () => {
  const [, setError] = useState()

  return useCallback(
    (err: Error) => {
      setError(() => {
        throw err
      })
    },
    [setError]
  )
}
