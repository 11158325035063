import React, { useState } from 'react'
import { createPortal } from 'react-dom'

export interface PortalProps {
  /**
   * portal 될 엘리먼트의 셀렉터 (document.querySelector)
   */
  selector?: string
  /**
   * portal 될 엘리먼트의 셀렉터가 찾아지기 전에도 보여줄것인지에 대한 여부
   */
  forceRenderBeforeElementFinded?: boolean
}

const Portal: React.FC<PortalProps> = ({ children, selector, forceRenderBeforeElementFinded }) => {
  const [rootElement, setRootElement] = useState<Element | HTMLElement | null>(null)

  React.useEffect(() => {
    setRootElement((selector && document.querySelector(selector)) || document.body)
  }, [selector])

  return <>{rootElement ? createPortal(children, rootElement) : forceRenderBeforeElementFinded ? children : null}</>
}

export default Portal
