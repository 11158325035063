export const COLORS = {
  white: '#FFFFFF',
  black: '#000000',
  blackOpacity5: 'rgb(0, 0, 0, 0.5)',
  blackOpacity9: 'rgb(0, 0, 0, 0.7)',
  blue50: '#EBF7FA',
  blue800: '#0A86B7',
  navyBlueOpacity: 'rgb(1, 41, 81, 0.07)',
  carrot50: '#FFF5F0',
  carrot100: '#FFE2D2',
  carrot200: '#FFD2B9',
  carrot300: '#FFBC97',
  carrot400: '#FF9E66',
  carrot500: '#FF7E36',
  carrot500Opacity: '#ffc59e',
  carrot600: '#FA6616',
  gray100: '#F2F3F6',
  gray200: '#EAEBEE',
  gray300: '#DCDEE3',
  gray400: '#D1D3D8',
  gray500: '#ADB1BA',
  gray600: '#868B94',
  gray700: '#4D5159',
  gray900: '#212124',
  grayLine: '#E9ECEF',
  grayIosSwitch: '#d9d9d9',
  grayAosSwitch: '#8b8b8b',
  green50: '#E8FAF6',
  green500: '#00B493',
  green800: '#008C72',
  red50: '#FFF3F2',
  red600: '#FF1720',
  red800: '#E81300',
  yellow50: '#FFF7E6',
  yellow600: '#FFC552',
  yellow800: '#CF6400',
}

export const LOADER_SPIN_SPEED = '800ms'
