import React, { MouseEvent } from 'react'
import classNames from 'classnames'

import styles from './heart_button.scss'
import Heart, { HeartIconProps } from '../../icons/Heart'

export interface HeartButtonProps extends Pick<HeartIconProps, 'strokeColor' | 'size'> {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  filled?: boolean
  filledColor?: string
  className?: string
}

const HeartButton: React.FC<HeartButtonProps> = ({
  onClick,
  strokeColor,
  filled = false,
  size,
  className,
  filledColor = '#FF7E36',
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        styles.heartButton,
        {
          [styles.filled]: filled,
        },
        className
      )}>
      <Heart size={size} fillColor={filled ? filledColor : '#fff'} strokeColor={filled ? filledColor : strokeColor} />
    </button>
  )
}

export default HeartButton
