import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

interface UseFallbackImageOptions {
  src?: string
  fallbackSrc: string
}

export const useFallbackImage = ({ src, fallbackSrc }: UseFallbackImageOptions) => {
  const isMount = useRef(false)
  const [profileSrc, setProfileSrc] = useState(src || fallbackSrc)

  useEffect(() => {
    if (isMount.current && src !== profileSrc && src !== fallbackSrc) {
      setProfileSrc(src || fallbackSrc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, fallbackSrc])

  useEffect(() => {
    isMount.current = true
  }, [])

  const handleLoadError = useCallback(() => {
    setProfileSrc(fallbackSrc)
  }, [fallbackSrc])

  return useMemo(
    () => ({
      profileSrc,
      handleLoadError,
    }),
    [handleLoadError, profileSrc]
  )
}
