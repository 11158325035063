import { combineEpics } from 'redux-observable'
import { map, filter, catchError, mergeMap } from 'rxjs/operators'

import { Epic } from '@src/types/epic'
import { reportError } from '@src/ducks/report'
import { reqEmailCode, resEmailCode, reqUpdateEmail, resUpdateEmail } from '@src/ducks/email'
import { asyncMap } from '@src/utils'
import { STORAGE_KEY_EMAIL } from '@src/constants/api'
import { updateUserState } from '@src/ducks/user'

export const reqEmailCode$$: Epic = (action$, state$, { karrotauth, bridge, t }) =>
  action$.pipe(
    filter(reqEmailCode.match),
    asyncMap(({ payload: { email } }) => {
      const {
        user: { id },
      } = state$.value
      return karrotauth.reqEmailCode$(id, email).pipe(
        map((data) => {
          if (data.status) {
            return resEmailCode({ message: data.status.message })
          }

          bridge.openToast({ toast: { body: t('my_account.email.message.email_sent') } })

          return resEmailCode({ message: null })
        }),
        catchError((err) => [reportError(err)])
      )
    })
  )

export const reqUpdateEmail$$: Epic = (action$, state$, { karrotauth, bridge, t, storage }) =>
  action$.pipe(
    filter(reqUpdateEmail.match),
    asyncMap(({ payload: { email, code, history, isRoot } }) => {
      const {
        user: { id },
      } = state$.value
      return karrotauth.registerEmail$(id, email, code).pipe(
        mergeMap((data) => {
          if (data.status) {
            return [resUpdateEmail({ message: data.status.message })]
          }

          storage.setItemAtLocal(STORAGE_KEY_EMAIL, email)
          bridge.openToast({ toast: { body: t('my_account.email.message.register_done') } })

          if (isRoot) {
            bridge.closeRouter({})
            return [resUpdateEmail({ message: null }), updateUserState({ email })]
          }
          history.pop()

          return [resUpdateEmail({ message: null }), updateUserState({ email })]
        }),
        catchError((err) => [reportError(err)])
      )
    })
  )

export default combineEpics(reqEmailCode$$, reqUpdateEmail$$)
