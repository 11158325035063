import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@src/ducks'

export const userSelector = createSelector(
  (state: RootState) => state.user,
  (d) => d
)
export const userInfoSelector = createSelector(userSelector, (d) => ({
  email: d.email,
  emailVerifiedAt: d.emailVerifiedAt,
  phone: d.phone,
  identityVerification: d.identityVerification,
  oauthProvider: d.oauthProvider,
  oauthProviderId: d.oauthProviderId,
  isPrepared: d.isPrepared,
}))

export const isLoginSelector = createSelector(userSelector, (d) => !!d)
export const userExtendSelector = createSelector(userSelector, (d) => d.userExtend)
export const webCrawlAllowedSelector = createSelector(userExtendSelector, (d) => d.webCrawlAllowed)
export const adAllowedSelector = createSelector(userExtendSelector, (d) => d.adAllowed)

export const checkinRegionsSelector = createSelector(userSelector, (d) => d.checkinRegions)
