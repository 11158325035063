import { useLocation } from 'react-router-dom'
import querystring from 'querystring'
import { useMemo } from 'react'

export const useQuery = () => {
  const location = useLocation()
  const queryString = useMemo(() => {
    return querystring.parse(location.search.split('?')[1])
  }, [location.search])

  return queryString
}
