import { Middleware } from 'redux'
import * as Sentry from '@sentry/browser'
import { LOCATION_CHANGE } from 'connected-react-router'

import { reportError } from '@src/ducks/report'

const excludeActions = [LOCATION_CHANGE, reportError.type]

const sentryMiddleware: Middleware = () => (next) => (action) => {
  if (!excludeActions.includes(action.type)) {
    Sentry.addBreadcrumb({
      category: 'redux-action',
      level: Sentry.Severity.Info,
      message: action.type,
    })
  }

  return next(action)
}
export default sentryMiddleware
