import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import { Global, css } from '@emotion/react'
import { vars } from '@seed-design/design-token'
import VConsole from 'vconsole'

import ErrorPage from '@src/pages/Error'

import { setApisCountry, setStorageData } from './api'
import Router from './router'
import { history, InitialState } from './store/store'
import StoreProvider from './store'
import { useAsyncError } from './hooks/useAsyncError'
import { useEnhancedInfo } from './hooks/useEnhancedInfo'
import { DEFAULT_COUNTRY_KEY } from './constants/api'
import { DEFAULT_LANGUAGE } from './i18n'
import { formatAcceptLanguage } from './utils'

const App: React.FC = () => {
  useEffect(() => {
    let vConsole: any = null
    if (process.env.APP_ENV === 'alpha') {
      vConsole = new VConsole({ theme: 'dark' })
    }
    return () => {
      if (!vConsole) return
      vConsole.destroy()
    }
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={ErrorPage}>
      <InitializeAppStage />
      <Global
        styles={css`
          body {
            color: ${vars.$scale.color.gray900};
          }
        `}
      />
    </Sentry.ErrorBoundary>
  )
}

export default App

const InitializeAppStage: React.FC = () => {
  const { i18n } = useTranslation()
  const throwAsyncError = useAsyncError()
  const { getInfo } = useEnhancedInfo()
  const [initialState, setinitialState] = useState<InitialState>()

  useEffect(() => {
    getInfo()
      .then(({ app, region, user }) => {
        if (!app) {
          throw new Error(`bridge.info is not working, app: ${JSON.stringify(app)}`)
        }

        const formattedLocale = formatAcceptLanguage(app.locale || DEFAULT_LANGUAGE)
        i18n.changeLanguage(formattedLocale)
        setApisCountry(app.country || DEFAULT_COUNTRY_KEY)
        user && setStorageData(user.authToken, app.userAgent, formattedLocale, app.deviceIdentity)

        setinitialState({
          app,
          region,
          user,
        })
      })
      .catch((err) => {
        throwAsyncError(err)
      })
  }, [getInfo, i18n, throwAsyncError])

  if (!initialState) {
    return null
  }

  return (
    <StoreProvider initialState={initialState}>
      <Router history={history} />
    </StoreProvider>
  )
}
