import { Info } from '@src/types/common'

export const REGEX_VALIDATE_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const REGEX_URL = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/
export const REGEX_NON_NUMBER = /[^0-9]*/g

export const PHONE_NUMBER_MASK: { [key in NonNullable<Info['app']>['country'] | 'US']: string } = {
  KR: 'XXX XXXX XXXX',
  GB: 'XXXXX XXXXXX',
  JP: 'XXX XXXX XXXX',
  CA: 'XXX XXX XXXX',
  US: 'XXX XXX XXXX',
}
