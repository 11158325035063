import React from 'react'
import ReactSwitch, { ReactSwitchProps } from 'react-switch'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import { vars } from '@seed-design/design-token'

import { COLORS } from '@src/constants/styles'
import withOS from '@src/hocs/withOS'

interface SwitchProps extends ReactSwitchProps {
  os?: 'Cupertino' | 'Android'
  className?: string
}

const Switch: React.FC<SwitchProps> = ({ className, ...props }) => {
  return (
    <SwitchWrapper className={className}>
      <Global
        styles={css`
          .react-switch-bg {
            background: ${props.os === 'Android' && props.checked
              ? `${vars.$scale.color.carrot500} !important`
              : 'none'};
            opacity: ${props.os === 'Android' && props.checked ? `0.5 !important` : 'none'};
          }
        `}
      />
      {props.os === 'Cupertino' ? (
        <ReactSwitch
          width={51}
          height={31}
          handleDiameter={27}
          onColor={COLORS.carrot500}
          offColor={COLORS.gray500}
          boxShadow="0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)"
          activeBoxShadow="0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)"
          checkedIcon={false}
          uncheckedIcon={false}
          {...props}
        />
      ) : (
        <ReactSwitch
          width={34}
          height={14}
          handleDiameter={20}
          onColor={COLORS.carrot500Opacity}
          onHandleColor={props.checked ? COLORS.carrot500 : COLORS.white}
          offColor={COLORS.gray500}
          boxShadow="0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)"
          activeBoxShadow="0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)"
          checkedIcon={false}
          uncheckedIcon={false}
          {...props}
        />
      )}
    </SwitchWrapper>
  )
}

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 51px;
  height: 31px;
`

export default withOS(Switch)
