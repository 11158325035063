import React from 'react'
import styled from '@emotion/styled'

const Svg = styled.svg`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none;
`

const ArrowDown: React.FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.93353 8.43427C5.24595 8.12185 5.75248 8.12185 6.0649 8.43427L11.9992 14.3686L17.9335 8.43427C18.246 8.12185 18.7525 8.12185 19.0649 8.43427C19.3773 8.74669 19.3773 9.25322 19.0649 9.56564L12.5649 16.0656C12.2525 16.3781 11.746 16.3781 11.4335 16.0656L4.93353 9.56564C4.62111 9.25322 4.62111 8.74669 4.93353 8.43427Z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default ArrowDown
