// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kf-3OuMB{position:relative;overflow:hidden}.kf-llQyd{position:absolute;top:0;left:0;right:0;bottom:0;width:100%;height:100%;border:1px solid rgba(0,0,0,0.05);border-radius:50%}.kf-1FEwV{display:block;width:100%;height:100%;object-fit:cover;border-radius:50%}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileAvatarWrapper": "kf-3OuMB",
	"border": "kf-llQyd",
	"image": "kf-1FEwV"
};
export default ___CSS_LOADER_EXPORT___;
