import React from 'react'

import { COLORS } from '@src/constants/styles'

interface ProgressIndicatorProps {
  width?: number
  height?: number
  color?: string
  opacity?: number
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  width = 40,
  height = 40,
  color = COLORS.black,
  opacity = 0.25,
  ...props
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 1.90735e-06 20 0C18.6193 1.90735e-06 17.5 1.11929 17.5 2.5C17.5 3.88071 18.6193 5 20 5C28.2843 5 35 11.7157 35 20C35 28.2843 28.2843 35 20 35C11.7167 35 5.00156 28.2858 5 20.0029L5 20C5 18.6193 3.88071 17.5 2.5 17.5C1.11929 17.5 0 18.6193 0 20Z"
        fill={color}
        fillOpacity={opacity}
      />
    </svg>
  )
}

export default ProgressIndicator
