import React, { memo } from 'react'
import cn from 'classnames'

import styles from './circular_progress.scss'

interface Props {
  color?: string
  size?: number
}

const CircularProgress: React.FC<Props> = ({ size = 24, color = '#222222' }) => {
  return (
    <div
      className={cn(styles.circularProgress)}
      style={{
        height: size,
        width: size,
      }}>
      <div className={styles.circularWrapper}>
        <div className={styles.spinner}>
          <div className={styles.circle} style={{ height: size / 2, width: size }}>
            <div
              className={styles.circleInner}
              style={{
                border: `${size / 10}px solid ${color}`,
                borderBottom: `${size / 10}px solid transparent`,
                borderRight: `${size / 10}px solid transparent`,
              }}
            />
          </div>
          <div className={cn(styles.circle, styles.circle2)} style={{ height: size / 2, width: size }}>
            <div
              className={styles.circleInner}
              style={{
                border: `${size / 10}px solid ${color}`,
                borderBottom: `${size / 10}px solid transparent`,
                borderRight: `${size / 10}px solid transparent`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(CircularProgress)
