import React from 'react'
import { History } from 'history'
import { ConnectedRouter } from 'connected-react-router'

import Routes from './Routes'

interface Props {
  history: History
}
const Router: React.FC<Props> = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  )
}
export default Router
