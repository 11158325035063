import { Observable } from 'rxjs'
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { createAxiosInterceptors } from 'plantae/axios'
import authPlugin from '@daangn/plantae-plugin-auth'

import { storage } from '@src/api'
import { API_URLS } from '@src/config'

// eslint-disable-next-line no-restricted-imports
import { bridge } from '../bridge'

export default class Request {
  private axios: AxiosInstance

  constructor(baseURL: string, private baseUrlKey: string) {
    this.axios = axios.create({ baseURL })
    const { request, response } = createAxiosInterceptors({
      client: this.axios,
      plugins: [
        authPlugin({
          bridge,
          options: {
            fallbackAuthToken: false,
          },
        }),
      ],
    })

    this.axios.interceptors.request.use(request.onFulfilled, request.onRejected)
    this.axios.interceptors.response.use(response.onFulfilled, response.onRejected)

    this.axios.interceptors.response.use((response) => {
      response.data = camelcaseKeys(response.data, { deep: true })
      return response
    })
  }

  private _request$<Response>(
    method = 'get',
    url: string,
    config: AxiosRequestConfig = {}
  ): Observable<AxiosResponse<Response>> {
    return new Observable((subscriber) => {
      // @ts-ignore
      this.axios({
        method,
        url,
        ...config,
      })
        .then((res: AxiosResponse<Response>) => {
          subscriber.next(res)
          subscriber.complete()
        })
        .catch((err: AxiosError) => subscriber.error(err.response || err))
    })
  }

  public setBaseUrl(countryKey: string) {
    this.axios.defaults.baseURL = API_URLS[countryKey][this.baseUrlKey]
  }

  public setTokenHeader() {
    this.axios.interceptors.request.use(
      (config) => {
        config.headers['x-auth-token'] = storage.getToken()
        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )
  }

  public setUserAgentHeader() {
    this.axios.interceptors.request.use(
      (config) => {
        config.headers['x-user-agent'] = storage.getUserAgent()
        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )
  }

  public setAcceptLanguageHeader() {
    this.axios.interceptors.request.use(
      (config) => {
        config.headers['accept-language'] = storage.getAcceptLanguage()
        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )
  }

  public setDeviceIdentityHeader() {
    this.axios.interceptors.request.use(
      (config) => {
        config.headers['x-device-identity'] = storage.getDeviceIdentity()
        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )
  }

  public get$<Response = any>(url: string, params = {}, config = {}) {
    return this._request$<Response>('get', url, { params, ...config })
  }

  public delete$(url: string, params = {}, config = {}) {
    return this._request$('delete', url, { params, ...config })
  }

  public post$<Response = any>(url: string, data = {}, config = {}) {
    return this._request$<Response>('post', url, { data, ...config })
  }

  public put$<Response = any>(url: string, data = {}, config = {}) {
    return this._request$<Response>('put', url, { data, ...config })
  }
}
