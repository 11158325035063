import { createAction, createReducer } from '@reduxjs/toolkit'

import { Info } from '@src/types/common'

export const resetApp = createAction('RESET_APP')

export const INITIAL_STATE: Info['app'] = {
  deviceIdentity: '',
  userAgent: '',
  locale: '',
  appHost: '',
  country: 'KR',
  appVersion: '',
  appVersionCode: '',
}

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(resetApp, () => INITIAL_STATE)
})
