import { bridge } from './bridge'

export const TARGET = process.env.NODE_ENV
export const API_URLS = process.env.API_URLS
export const SCHEME_PREFIX = process.env.SCHEME_PREFIX
export const APP_URLS = process.env.APP_URLS
export const APP_ENV = process.env.APP_ENV
export const VERSION = process.env.VERSION
export const SENTRY_VERSION = process.env.SENTRY_VERSION
export const IS_ALPHA = APP_ENV === 'alpha'
export const IS_PROD = APP_ENV === 'production'
export const IS_NATIVE = TARGET === 'production'

export const IS_DESKTOP = bridge.driver.getCurrentEnvironment() === 'Web'
// export const IS_DEV = false

export const AUTH_TOKEN = process.env.REACT_APP_DEFAULT_AUTH_TOKEN || ''
export const USER_AGENT = process.env.REACT_APP_DEFAULT_USER_AGENT || ''
export const USER_ID = process.env.REACT_APP_DEFAULT_USER_ID || ''
export const TARGET_LOCALE = process.env.REACT_APP_TARGET_LOCALE || ''
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || ''

const [TARGET_LANGUAGE, TARGET_COUNTRY] = TARGET_LOCALE.split('_')

export { TARGET_LANGUAGE, TARGET_COUNTRY }
