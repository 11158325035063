import * as Sentry from '@sentry/react'

import { APP_ENV, SENTRY_VERSION, SENTRY_DSN, IS_NATIVE } from './config'
import configureStore from './store/store'

let store: ReturnType<typeof configureStore>

const filtered = 'filtered'
const maskingPrivacy = (state: any) => {
  if (state.region) {
    state.region = filtered
  }

  if (state.user) {
    const userId = state.user.id
    state.user = {}
    state.user.id = userId
  }

  return state
}

export const setStore = (_store: ReturnType<typeof configureStore>) => {
  store = _store
}

export const initSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: SENTRY_VERSION,
    normalizeDepth: 10,
    maxBreadcrumbs: 50,
    environment: APP_ENV,
    beforeSend(event) {
      if (IS_NATIVE) {
        if (!event.extra) {
          event.extra = {}
        }

        const state = store?.getState()

        if (state) {
          event.extra.state = maskingPrivacy(JSON.parse(JSON.stringify(state)))
        }

        if (event.exception?.values) {
          for (let i = 0; i < event.exception.values.length; i++) {
            const value = event.exception.values[i]

            if (value.stacktrace?.frames) {
              value.stacktrace.frames = value.stacktrace.frames.map((frame) => {
                if (!frame.filename) {
                  return frame
                }

                const splitted = frame.filename.split('/static/js/')
                frame.filename = 'https://webview-sourcemap.daangn.com/static/js/' + splitted[1]

                return frame
              })
            }
          }
        }

        return event
      }

      return null
    },
  })
}
