import { combineEpics } from 'redux-observable'

import { Epic as _Epic, EpicDependencies as _EpicDependencies } from '@src/types/epic'

import article from './article'
import email from './email'
import phone from './phone'
import report from './report'
import user from './user'

export type Epic = _Epic
export type EpicDependencies = _EpicDependencies

export default combineEpics(article, email, phone, report, user)
