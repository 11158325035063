import { combineEpics } from 'redux-observable'
import { map, filter, catchError, exhaustMap, mergeMap } from 'rxjs/operators'

import { Epic } from '@src/types/epic'
import { reqPhoneCode, resPhoneCode, reqUpdatePhoneNumber, resUpdatePhoneNumber } from '@src/ducks/phone'
import { reportError } from '@src/ducks/report'
import { STORAGE_KEY_PHONE_NUMBER } from '@src/constants/api'
import { updateUserState } from '@src/ducks/user'

export const reqPhoneCode$$: Epic = (action$, _, { hoian }) =>
  action$.pipe(
    filter(reqPhoneCode.match),
    exhaustMap(({ payload: { phone } }) => {
      return hoian.reqPhoneCode$(phone).pipe(
        map((data) => {
          if (data.status && data.status.code !== 'ok') {
            return resPhoneCode({ message: data.status.message, code: data.status.code })
          }

          return resPhoneCode({ message: null, code: null })
        }),
        catchError((err) => [reportError(err)])
      )
    })
  )

export const reqUpdatePhoneNumber$$: Epic = (action$, _, { hoian, bridge, t, storage }) =>
  action$.pipe(
    filter(reqUpdatePhoneNumber.match),
    exhaustMap(({ payload: { phone, code, history } }) => {
      return hoian.updatePhoneNumber$(phone, code).pipe(
        mergeMap((data) => {
          if (data.status && data.status.code !== 'ok') {
            return [resUpdatePhoneNumber({ message: data.status.message, code: data.status.code })]
          }

          storage.setItemAtLocal(STORAGE_KEY_PHONE_NUMBER, phone)
          // 본인인증에서 전화번호 변경하는 경우 변경한 전화번호를 네이티브에 전달하기 위해서 추가
          bridge.setItemInStorage({ storage: { key: 'karrot_auth_current_phone_number', value: phone } })
          bridge.openToast({ toast: { body: t('my_account.phone.message.update_done') } })
          history.pop()

          return [resUpdatePhoneNumber({ message: null, code: null }), updateUserState({ phone })]
        }),
        catchError((err) => [reportError(err)])
      )
    })
  )

export default combineEpics(reqPhoneCode$$, reqUpdatePhoneNumber$$)
