import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

export const InlineTextButton = styled.span`
  position: absolute;
  padding: 12px 16px;
  top: 0;
  right: 0;
  font-size: 1rem;
  color: ${vars.$scale.color.carrot500};
`

export const OutlineTextButton = styled.div`
  padding: 8px 65.5px;
  border: 1px solid ${vars.$scale.color.gray300};
  border-radius: 5px;
  font-size: 1rem;
`
