import { combineEpics } from 'redux-observable'
import { filter, map } from 'rxjs/operators'
import { captureException, setExtra } from '@sentry/react'

import { reportError } from '@src/ducks/report'
import { Epic } from '@src/types/epic'
import { noop } from '@src/utils/noop'

export const reportError$$: Epic = (action$, _, { bridge, t }) =>
  action$.pipe(
    filter(reportError.match),
    map(({ payload: err }) => {
      setExtra('error', err)
      const error = err instanceof Error ? err : new Error(JSON.stringify(err))
      captureException(error)

      bridge.openToast({ toast: { body: t('common.error_occured') } })

      return noop()
    })
  )

export default combineEpics(reportError$$)
