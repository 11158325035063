import { createAction, createReducer } from '@reduxjs/toolkit'

import { RegionInfo } from '@src/types/common'
import { createHistoryAction } from '@src/utils/action'

export const reqArticleRegions = createAction('RESET_REGION')
export const resArticleRegions = createAction<{ articleRegions: RegionInfo[] }>('RES_ARTICLE_REGIONS')

export const reqUpdateArticlesRegion =
  createHistoryAction<{ selectedArticleRegion: RegionInfo; selectedCheckinRegion: RegionInfo }>(
    'REQ_UPDATE_ARTICLES_REGION'
  )
export const resUpdateArticlesRegion = createAction<{ message: string | null }>('RES_UPDATE_ARTICLES_REGION')

interface State {
  articleRegions: RegionInfo[]
  updateArticlesRegionMessage: string | null
}

export const INITIAL_STATE: State = {
  articleRegions: [],
  updateArticlesRegionMessage: null,
}

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(resArticleRegions, (state, { payload }) => {
      if (payload.articleRegions) {
        return {
          articleRegions : payload.articleRegions
        }
      }
    })
    .addCase(resUpdateArticlesRegion, (state, { payload }) => {
      state.updateArticlesRegionMessage = payload.message
    })
})
