import { useState, useEffect, useRef } from 'react'

export const useCheckTimer = () => {
  const ref = useRef(0)
  const [isEnd, setIsEnd] = useState(true)
  const [trigger, setTrigger] = useState({})

  useEffect(() => {
    if (ref.current === 0) {
      return
    }

    const timerInterval = setInterval(() => {
      ref.current -= 1
      console.log(ref.current)
      if (ref.current < 1) {
        clearInterval(timerInterval)
        setIsEnd(true)
      }
    }, 1000)

    return () => {
      clearInterval(timerInterval)
    }
  }, [trigger])

  const triggerTimer = (seconds: number) => {
    ref.current = seconds
    setIsEnd(false)
    setTrigger({})
  }

  return { isEnd, triggerTimer }
}
