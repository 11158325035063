import { Middleware } from 'redux'
import { push } from 'connected-react-router'

import { reportError } from '@src/ducks/report'

const reportMiddleware: Middleware = (store) => (next) => (action) => {
  try {
    return next(action)
  } catch (err) {
    store.dispatch(reportError(err))

    return next(push('/error'))
  }
}

export default reportMiddleware
