import { combineEpics } from 'redux-observable'
import { map, filter, catchError, debounceTime, switchMap, exhaustMap } from 'rxjs/operators'
import { forkJoin } from 'rxjs'

import {
  reqUpdateWebCrawlAllowed,
  resUpdateWebCrawlAllowed,
  reqCheckinRegions,
  resCheckinRegions,
  initStateFromMyAccount,
  updateUserState,
  initStateFromOtherSettings,
  reqUpdateAdAllowed,
  resUpdateAdAllowed,
} from '@src/ducks/user'
import { reportError } from '@src/ducks/report'
import { asyncMap } from '@src/utils'
import { Epic } from '@src/types/epic'
import {
  STORAGE_KEY_WEB_CRAWL_ALLOWED,
  STORAGE_KEY_EMAIL,
  STORAGE_KEY_PHONE_NUMBER,
  STORAGE_KEY_IDENTITY_VERIFICATION,
  STORAGE_KEY_AD_ALLOWED,
} from '@src/constants/api'
import { RegionInfo, CheckedInH3LocationInfo } from '@src/types/common'

export const initStateFromMyAccount$$: Epic = (action$, state$, { hoian, storage }) =>
  action$.pipe(
    filter(initStateFromMyAccount.match),
    exhaustMap(() => {
      const {
        app: { country },
        user: { id },
      } = state$.value
      const isEmailInclude = country === 'KR'

      return hoian.getMyAccountUserInfo$(id, isEmailInclude).pipe(
        map((data) => {
          if (!data.user) {
            throw new Error(`user is not defined at hoian.getMyAccountUserInfo. ${JSON.stringify(data)}`)
          }

          const {
            user: { email, phone, identityVerification, oauthProvider, oauthProviderDisplayId },
          } = data

          if (isEmailInclude) {
            if (email) {
              storage.setItemAtLocal(STORAGE_KEY_EMAIL, email)
            } else {
              storage.removeItemAtLocal(STORAGE_KEY_EMAIL)
            }
          }

          storage.setItemAtLocal(STORAGE_KEY_PHONE_NUMBER, phone)
          storage.setItemAtLocal(STORAGE_KEY_IDENTITY_VERIFICATION, JSON.stringify(identityVerification))

          return updateUserState(
            isEmailInclude
              ? {
                  email,
                  phone,
                  identityVerification,
                  oauthProvider,
                  oauthProviderId: oauthProviderDisplayId,
                  isPrepared: true,
                }
              : {
                  phone,
                  identityVerification,
                  oauthProvider,
                  oauthProviderId: oauthProviderDisplayId,
                  isPrepared: true,
                }
          )
        }),
        catchError((err) => [reportError(err)])
      )
    })
  )

export const initStateFromOtherSettings$$: Epic = (action$, state$, { hoian, storage }) =>
  action$.pipe(
    filter(initStateFromOtherSettings.match),
    exhaustMap(() => {
      const {
        user: { id },
      } = state$.value
      return forkJoin([hoian.getOtherSettingsUserInfo$(id), hoian.getAdAllowed$()]).pipe(
        map(([data, dataWithAdAllowed]) => {
          if (!data.user) {
            throw new Error(`user is not defined at hoian.getOtherSettingsUserInfo. ${JSON.stringify(data)}`)
          }

          if (!dataWithAdAllowed || dataWithAdAllowed.enabled == null) {
            throw new Error(`enabled is not defined at hoian.getAdAllowed. ${JSON.stringify(dataWithAdAllowed)}`)
          }

          const {
            user: { userExtend },
          } = data

          const { enabled } = dataWithAdAllowed

          storage.setItemAtLocal(STORAGE_KEY_WEB_CRAWL_ALLOWED, userExtend.webCrawlAllowed)
          storage.setItemAtLocal(STORAGE_KEY_AD_ALLOWED, enabled)

          return updateUserState({
            userExtend: {
              ...userExtend,
              adAllowed: enabled,
            },
          })
        }),
        catchError((err) => [reportError(err)])
      )
    })
  )

export const reqUpdateWebCrawlAllowed$$: Epic = (action$, _, { hoian, bridge, t, storage }) =>
  action$.pipe(
    filter(reqUpdateWebCrawlAllowed.match),
    debounceTime(300),
    switchMap(({ payload: { webCrawlAllowed } }) => {
      return hoian.updateWebCrawlAllowed$(webCrawlAllowed).pipe(
        map((data) => {
          if (!data.userExtend) {
            throw new Error(`userExtend is not defined at hoian.updateWebCrawlAllowed. ${JSON.stringify(data)}`)
          }

          const {
            userExtend: { webCrawlAllowed },
          } = data

          bridge.openToast({ toast: { body: t('other_settings.index.message.setting_done') } })
          storage.setItemAtLocal(STORAGE_KEY_WEB_CRAWL_ALLOWED, JSON.stringify(webCrawlAllowed))

          return resUpdateWebCrawlAllowed({ webCrawlAllowed })
        }),
        catchError((err) => [resUpdateWebCrawlAllowed({ webCrawlAllowed: !webCrawlAllowed }), reportError(err)])
      )
    })
  )

export const reqUpdateAdAllowed$$: Epic = (action$, _, { hoian, bridge, t, storage }) =>
  action$.pipe(
    filter(reqUpdateAdAllowed.match),
    debounceTime(300),
    switchMap(({ payload: { adAllowed } }) => {
      return hoian.updateAdAllowed$(adAllowed).pipe(
        map((data) => {
          if (!data || data.enabled == null) {
            throw new Error(`enabled is not defined at hoian.updateAdAllowed. ${JSON.stringify(data)}`)
          }

          const { enabled } = data

          bridge.openToast({ toast: { body: t('other_settings.index.message.setting_done') } })
          storage.setItemAtLocal(STORAGE_KEY_AD_ALLOWED, JSON.stringify(enabled))

          return resUpdateAdAllowed({ adAllowed: enabled })
        }),
        catchError((err) => [resUpdateAdAllowed({ adAllowed: !adAllowed }), reportError(err)])
      )
    })
  )

export const reqCheckinRegions$$: Epic = (action$, state$, { hoian }) =>
  action$.pipe(
    filter(reqCheckinRegions.match),
    asyncMap(() => {
      const {
        user: { id },
      } = state$.value

      return hoian.getChangeableRegions$(id).pipe(
        map((data) => {
          if (!data.user) {
            throw new Error(`user is not defined at hoian.getChangeableRegions. ${JSON.stringify(data)}`)
          }

          // h3 location name 유저 대응 (userLocationCheckinCounts가 존재하면 h3 user)
          const checkedInH3Locations = data?.user?.userLocationCheckinCounts
          const isH3User = Array.isArray(checkedInH3Locations)

          if (isH3User) {
            const checkinLocations: RegionInfo[] = checkedInH3Locations.map((item: CheckedInH3LocationInfo) => ({
              displayName: item.locationName,
              regionId: null,
              regionName: null,
              locationNameId: item.locationNameId,
              locationName: item.locationName,
              count: item.articleCount,
            }))

            return resCheckinRegions({ checkinRegions: checkinLocations })
          }

          // region 유저
          const {
            checkinCounts,
            userExtend: { region1, region2 },
          } = data.user

          const checkinRegions: RegionInfo[] = checkinCounts
            .filter((region: RegionInfo) => {
              const regionId = region.regionId
              return regionId === region1?.id || regionId === region2?.id
            })
            .map((item: RegionInfo) => ({
              displayName: item.regionName,
              regionId: item.regionId,
              regionName: item.regionName,
              locationNameId: null,
              locationName: null,
              count: item.count,
            }))

          return resCheckinRegions({ checkinRegions })
        }),
        catchError((err) => [reportError(err)])
      )
    })
  )

export default combineEpics(
  initStateFromMyAccount$$,
  initStateFromOtherSettings$$,
  reqUpdateWebCrawlAllowed$$,
  reqUpdateAdAllowed$$,
  reqCheckinRegions$$
)
