import { createFetch } from 'plantae'
import commonHeadersPlugin from '@daangn/plantae-plugin-common-headers'
import authPlugin from '@daangn/plantae-plugin-auth'

import { bridge } from '@src/bridge'
import { storage } from '@src/api/index'

export const fetchWithAuth = createFetch({
  plugins: [
    commonHeadersPlugin({
      bridge,
    }),
    authPlugin({
      bridge,
      options: {
        fallbackAuthToken: {
          value: storage.getToken(),
        },
      },
    }),
  ],
})
