import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@src/ducks'

import { userSelector } from './user'

export const phoneSelector = createSelector(
  (state: RootState) => state.phone,
  (d) => d
)
export const phoneInfoSelector = createSelector(userSelector, phoneSelector, (user, phone) => ({
  phone: user.phone,
  phoneCodeMessage: phone.phoneCodeMessage,
  phoneCodeErrorCode: phone.phoneCodeErrorCode,
  isCodeSent: phone.isCodeSent,
  updatePhoneMessage: phone.updatePhoneMessage,
  updatePhoneErrorCode: phone.updatePhoneErrorCode
}))
