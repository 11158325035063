import React from 'react'
import { useSelector } from 'react-redux'

import MyAccountGlobalPage from '@src/pages/MyAccount/MyAccountGlobal'
import MyAccountPage from '@src/pages/MyAccount/MyAccountPage'
import { countrySelector } from '@src/selectors/app'

const MyAccount = () => {
  const country = useSelector(countrySelector)

  return country === 'KR' ? <MyAccountPage params={{}} /> : <MyAccountGlobalPage params={{}} />
}

export default MyAccount
