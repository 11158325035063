import { createAction, createReducer } from '@reduxjs/toolkit'

export const modifyAbTest = createAction<boolean>('MODIFY_AB_TEST')
export const toggleLogging = createAction('TOGGLE_LOGGING')

interface InitialState {
  abTest: boolean
  isLogging: boolean
}

export const INITIAL_STATE: InitialState = {
  abTest: false,
  isLogging: true,
}

export default createReducer<InitialState>(INITIAL_STATE, (builder) => {
  builder
    .addCase(modifyAbTest, (state, { payload }) => {
      state.abTest = payload
    })
    .addCase(toggleLogging, (state) => {
      state.isLogging = !state.isLogging
    })
})
