const REGEX_VALIDATE_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validatePhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length < 4 || phoneNumber.length > 15) {
    return false
  }

  return true
}
export const validatePhoneValidationCode = (validationCode: string) => {
  return validationCode.length === 6
}

export const validateEmailAddress = (email: string) => {
  return REGEX_VALIDATE_EMAIL.test(email)
}
export const validateEmailValidationCode = (validationCode: string) => {
  return validationCode.length === 6
}
