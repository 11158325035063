/* eslint-disable no-restricted-imports */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import ko_KR from './locales/user-settings.ko.json'
import en from './locales/user-settings.en.json'
import en_CA from './locales/user-settings.en_CA.json'
import en_GB from './locales/user-settings.en-GB.json'
import ja_JP from './locales/user-settings.ja.json'

export const DEFAULT_LANGUAGE = 'ko'

const resources = {
  ko: { user_settings: ko_KR.user_settings },
  en: { user_settings: en.user_settings },
  'en-CA': { user_settings: en_CA.user_settings },
  'en-GB': { user_settings: en_GB.user_settings },
  ja: { user_settings: ja_JP.user_settings },
}

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANGUAGE,
  fallbackLng: (nativeLanguageCode) => {
    const webLanguageCodes = {
      korean: 'ko-KR',
      japanese: 'ja-JP',
      canadianEnglish: 'en-CA',
      britishEnglish: 'en-GB',
      english: 'en-US',
    }
    if (/^ko(-|_|$)/i.test(nativeLanguageCode)) {
      return [webLanguageCodes.korean, webLanguageCodes.english]
    }
    if (/^ja(-|_|$)/i.test(nativeLanguageCode)) {
      return [webLanguageCodes.japanese, webLanguageCodes.english]
    }
    if (/^en(-|_)CA$/i.test(nativeLanguageCode)) {
      return [webLanguageCodes.canadianEnglish, webLanguageCodes.english]
    }
    if (/^en(-|_)GB$/i.test(nativeLanguageCode)) {
      return [webLanguageCodes.britishEnglish, webLanguageCodes.english]
    }
    if (/^en(-|_|$)/i.test(nativeLanguageCode)) {
      return [webLanguageCodes.english]
    }
    return [webLanguageCodes.english]
  },
  defaultNS: 'user_settings',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
