import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppScreen } from '@stackflow/plugin-basic-ui'

import { PageContainer, MenuContainer } from '@src/components/base/Container'
import { MenuTitle } from '@src/components/base/Title'
import { URL_LOCATION_POLICY, URL_SERVICE_POLICY } from '@src/constants/urls'
import { useEnhancedRouter } from '@src/hooks/useEnhancedRouter'
import { countrySelector } from '@src/selectors/app'
import { API_URLS, IS_PROD } from '@src/config'
import { HOIAN_KEY } from '@src/constants/api'
import IconBack from '@src/components/svg/IconBack'

const ServiceTermsPage: React.FC = () => {
  const { t } = useTranslation()
  const { push } = useEnhancedRouter()
  const country = useSelector(countrySelector) as 'KR' | 'CA' | 'US' | 'GB' | 'UK' | 'JP'

  const hoianUrl = API_URLS[country][HOIAN_KEY]

  let privacyPolicyURL = 'https://privacy.daangn.com/'

  switch (country) {
    case 'CA':
      privacyPolicyURL = 'https://ca.karrotmarket.com/policy/privacy'
      break
    case 'US':
      privacyPolicyURL = 'https://us.karrotmarket.com/policy/privacy'
      break
    case 'GB':
    case 'UK':
      privacyPolicyURL = 'https://uk.karrotmarket.com/policy/privacy'
      break
    case 'JP':
      privacyPolicyURL = 'https://jp.karrotmarket.com/policy/privacy'
      break
  }

  return (
    <AppScreen
      appBar={{
        title: t('my_account.index.menu.service_terms'),
        backButton: {
          renderIcon: () => <IconBack />,
          onClick: () => {},
        },
      }}>
      <PageContainer>
        <section>
          <MenuContainer
            onClick={() =>
              push({
                remote: `${hoianUrl}${URL_SERVICE_POLICY}`,
                navbarTitle: t('my_account.service_terms.menu.service_policy'),
              })
            }>
            <MenuTitle>{t('my_account.service_terms.menu.service_policy')}</MenuTitle>
          </MenuContainer>
          <MenuContainer
            onClick={() =>
              push({
                remote: privacyPolicyURL,
                navbarTitle: t('my_account.service_terms.menu.privacy_policy'),
              })
            }>
            <MenuTitle>{t('my_account.service_terms.menu.privacy_policy')}</MenuTitle>
          </MenuContainer>
          {country === 'KR' && (
            <MenuContainer
              onClick={() => {
                const operationPolicyScheme = IS_PROD
                  ? 'karrot://minikarrot/router?remote=https%3A%2F%2Fcs.kr.karrotmarket.com%2Fwv%2Ffaqs%2F12245&navbar=false&scrollable=false'
                  : 'karrot.alpha://minikarrot/router?remote=https%3A%2F%2Fcs.kr.karrotmarket.com%2Fwv%2Ffaqs%2F12245&navbar=false&scrollable=false'
                window.location.href = operationPolicyScheme
              }}>
              <MenuTitle>{t('my_account.service_terms.menu.operation_policy')}</MenuTitle>
            </MenuContainer>
          )}
          {country === 'KR' && (
            <MenuContainer
              onClick={() =>
                push({
                  remote: 'https://business.daangn.com/terms/privacy',
                  navbarTitle: t('my_account.service_terms.menu.business_policy'),
                })
              }>
              <MenuTitle>{t('my_account.service_terms.menu.business_policy')}</MenuTitle>
            </MenuContainer>
          )}
          {country === 'KR' && (
            <MenuContainer
              onClick={() =>
                push({
                  remote: `${hoianUrl}${URL_LOCATION_POLICY}`,
                  navbarTitle: t('my_account.service_terms.menu.location_policy'),
                })
              }>
              <MenuTitle>{t('my_account.service_terms.menu.location_policy')}</MenuTitle>
            </MenuContainer>
          )}
        </section>
      </PageContainer>
    </AppScreen>
  )
}

export default ServiceTermsPage
