import { from, Observable, ObservableInput } from 'rxjs'
import { switchMap, catchError, startWith, endWith } from 'rxjs/operators'
import { PayloadAction } from '@reduxjs/toolkit'

import { PENDING, FULFILLED, REJECTED } from '@src/constants/api'
import { updateAsyncState } from '@src/ducks/asyncState'

export const asyncMap = <S extends PayloadAction<any>, R>(fn: (action: S) => ObservableInput<R>) => {
  return (source$: Observable<S>) => {
    return source$.pipe(
      switchMap((data) => {
        const { type, payload } = data
        const normalizedID = `${type}::${JSON.stringify(payload)}`

        return from(fn(data)).pipe(
          startWith(updateAsyncState(normalizedID, PENDING)),
          endWith(updateAsyncState(normalizedID, FULFILLED)),
          catchError((action) => [...action, updateAsyncState(normalizedID, REJECTED)])
        )
      })
    )
  }
}
