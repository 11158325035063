import React, { useEffect } from 'react'
import { AppScreen } from '@stackflow/plugin-basic-ui'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useTranslation } from 'react-i18next'
import { BoxButton } from '@daangn/sprout-components-button'
import { useSelector } from 'react-redux'

import identificationDone from '@src/assets/images/identification_done.svg'
import IconBack from '@src/components/svg/IconBack'
import { useFlow } from '@src/router/Routes'
import { useDispatchWithHistory } from '@src/hooks/useDispatchWithHistory'
import { storage } from '@src/api'
import { initStateFromMyAccount } from '@src/ducks/user'
import { STORAGE_KEY_EMAIL, STORAGE_KEY_IDENTITY_VERIFICATION, STORAGE_KEY_PHONE_NUMBER } from '@src/constants/api'
import { bridge } from '@src/bridge'
import { phoneInfoSelector } from '@src/selectors/phone'

const IdentificationPageForPhoneNumberComplete = () => {
  const { t } = useTranslation()
  const dispatch = useDispatchWithHistory()
  const phoneInfo = useSelector(phoneInfoSelector)
  const { pop } = useFlow()

  useEffect(() => {
    const email = storage.getItemFromLocal(STORAGE_KEY_EMAIL) || ''
    const phone = storage.getItemFromLocal(STORAGE_KEY_PHONE_NUMBER) || ''
    const identityVerification = storage.getItemFromLocal(STORAGE_KEY_IDENTITY_VERIFICATION) || {}

    dispatch(initStateFromMyAccount({ email, phone, identityVerification }))
  }, [dispatch])

  useEffect(() => {
    bridge.setItemInStorage({ storage: { key: 'karrot_auth_current_phone_number', value: phoneInfo.phone } })
  }, [phoneInfo])

  return (
    <AppScreen
      appBar={{
        backButton: {
          renderIcon: () => <IconBack />,
          onClick: () => {},
        },
        border: false,
      }}>
      <Container>
        <TopContainer>
          <TitleMessage>
            {t('my_account.identity_verification.update_phone_number_with_identification_done.title')}
          </TitleMessage>
          <Description>
            {t('my_account.identity_verification.update_phone_number_with_identification_done.description')}
          </Description>
        </TopContainer>
        <ImageContainer>
          <CenterImage src={identificationDone} alt="Icon for identification" />
        </ImageContainer>
        <BottomContainer>
          <BoxButton
            size="xlarge"
            isLoading={false}
            onClick={() => {
              pop()
            }}>
            {t('my_account.identity_verification.update_phone_number_with_identification_done.primary_action')}
          </BoxButton>
        </BottomContainer>
      </Container>
    </AppScreen>
  )
}

export default IdentificationPageForPhoneNumberComplete

const Container = styled.div`
  padding: 4px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 140px;
`

const TitleMessage = styled.h2`
  font-size: 1.5rem;
  color: ${vars.$scale.color.gray900};
  font-weight: 700;
  white-space: pre-wrap;
  line-height: 135%;
`

const Description = styled.p`
  font-size: 1rem;
  color: ${vars.$scale.color.gray900};
  font-weight: 400;
  margin-top: 0.375rem;
  white-space: pre-wrap;
  line-height: 135%;
`

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
`

const BottomContainer = styled.div`
  padding-top: 2.556rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-bottom: calc(12px + env(safe-area-inset-bottom, 0px));
  padding-bottom: calc(12px + constant(safe-area-inset-bottom, 0px));
`

const CenterImage = styled.img`
  width: 70%;
  height: auto;
`
