import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@src/ducks'

import { userSelector } from './user'

export const emailSelector = createSelector(
  (state: RootState) => state.email,
  (d) => d
)
export const emailInfoSelector = createSelector(userSelector, emailSelector, (user, email) => ({
  email: user.email,
  emailCodeMessage: email.emailCodeMessage,
  isCodeSent: email.isCodeSent,
  updateEmailMessage: email.updateEmailMessage,
}))
