import { useCallback } from 'react'

import { IS_DESKTOP, AUTH_TOKEN, USER_AGENT, TARGET_LOCALE, TARGET_COUNTRY, USER_ID } from '@src/config'
import { Info } from '@src/types/common'
import { bridge } from '@src/bridge'

const mockData = {
  app: {
    deviceIdentity: '',
    userAgent: USER_AGENT,
    locale: TARGET_LOCALE,
    appHost: '',
    country: TARGET_COUNTRY as 'GB' | 'KR',
    appVersion: '23.46.0',
    appVersionCode: '234600',
  },
  region: {
    id: 392,
    name: '역삼1동',
    fullname: '서울특별시 강남구 역삼1동',
    name1: '서울특별시',
    name2: '강남구',
    name3: '역삼1동',
    name1Id: 1,
    name2Id: 2,
    name3Id: 3,
    parentId: 1,
    centerCoordinates: {
      latitude: 37.50039415700105,
      longitude: 127.03641616735342,
    },
  },
  user: {
    authToken: AUTH_TOKEN,
    id: Number(USER_ID),
    checkinRegions: [],
    userExtend: {},
    phone: '02012345678',
    nickname: '당근이',
    profileImage: '',
    hashId: 'foobar',
  },
}

export const useEnhancedInfo = () => {
  const getInfo = useCallback(() => {
    if (IS_DESKTOP) {
      return new Promise<Info>((resolve) => {
        setTimeout(() => {
          const info = mockData
          resolve(info)
        })
      })
    }

    return Promise.all([bridge.getAppInfo({}), bridge.getRegionInfo({}), bridge.getUserInfo({})]).then(
      ([app, region, user]) => ({
        app: app.info.app,
        region: region.info.region,
        user: user.info.user,
      })
    )
  }, [])

  return { getInfo }
}
