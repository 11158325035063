import { Modal } from '@daangn/karrot-clothes'
import { ClassNames, SerializedStyles } from '@emotion/react'
import React, { ComponentProps } from 'react'

interface Props extends ComponentProps<typeof Modal> {
  bodyStyle: SerializedStyles
}

const FullSizeModal: React.FC<Props> = ({ children, bodyStyle, bodyClassName, ...props }) => {
  return (
    <ClassNames>
      {({ css, cx }) => (
        <Modal
          bodyClassName={cx(
            {
              [css`
                ${bodyStyle}
              `]: Boolean(bodyStyle),
            },
            bodyClassName
          )}
          {...props}>
          {children}
        </Modal>
      )}
    </ClassNames>
  )
}

export default FullSizeModal
