import { useCallback, useEffect, useMemo, useRef } from 'react'

import useBoolean from './useBoolean'

export default (
  initialState = false
): [
  boolean,
  () => void, // open
  (cb?: (() => void) | { [key: string]: any }) => void //close
] => {
  const [isModalOpen, setModalOpen, setModalClose] = useBoolean(initialState)
  const callbackRef = useRef<(() => void) | null>(null)

  useEffect(() => {
    if (!isModalOpen) {
      return
    }
    window.history.pushState({}, '')
    const onPopState = () => {
      if (isModalOpen) setModalClose()
    }
    window.addEventListener('popstate', onPopState)
    return () => window.removeEventListener('popstate', onPopState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen])

  useEffect(() => {
    if (!isModalOpen) {
      const callback = callbackRef.current
      callbackRef.current = null
      callback?.()
    }
  }, [isModalOpen])

  const handleModalClose = useCallback((cb?: (() => void) | any) => {
    window.history.back()
    callbackRef.current = typeof cb === 'function' ? cb : null
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => [isModalOpen, setModalOpen, handleModalClose], [isModalOpen])
}
