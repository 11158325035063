import { throwError } from 'rxjs'

interface APIError {
  err: Error
  origin: string
  api: string
}

export const throwApiError = ({ err, origin, api }: APIError) => {
  err.name = 'APIError'
  err.message = `Error in ${origin}/${api}`

  return throwError(err)
}
