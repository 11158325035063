import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

export const SectionTitle = styled.div`
  padding: 14px 16px;
  font-size: 0.8125rem;
  font-weight: bold;
`

export const MenuTitle = styled.div`
  font-size: 1rem;
`

export const MenuSubtitle = styled.div`
  color: ${vars.$scale.color.gray600};
  font-size: 0.8125rem;
`
