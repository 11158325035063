import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'

interface TimerProps {
  initialDuration: number
}

const Timer = forwardRef<unknown, TimerProps>(({ initialDuration }, ref) => {
  const { t } = useTranslation()
  const [startTime, setStartTime] = useState<Date>(new Date())
  const [timer, setTimer] = useState(initialDuration)

  useImperativeHandle(ref, () => ({
    startTimer(duration: number) {
      setTimer(duration)
      setStartTime(new Date())
    },
  }))

  useEffect(() => {
    if (timer === 0) {
      return
    }

    const timerInterval = setInterval(() => {
      setTimer(() => {
        const distance = new Date().getTime() - startTime.getTime()
        const countDown = timer - Math.floor(distance / 1000)

        if (countDown <= 0) {
          clearInterval(timerInterval)
          return 0
        }

        return countDown
      })
    }, 1000)

    return () => {
      clearInterval(timerInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime])

  const minute = Math.floor(timer / 60)
  const second = timer % 60

  return <>{t('timer.remained_time', { min: minute, sec: second })}</>
})

export default Timer
