import React, { useEffect } from 'react'
import { useActivityParams } from '@stackflow/react'

import { activities, useFlow } from './Routes'

interface Props {
  to: keyof typeof activities
}

const Redirect: React.FC<Props> = ({ to }) => {
  const { replace } = useFlow()
  const activityParams = useActivityParams()

  useEffect(() => {
    replace(to, activityParams as unknown as any)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default Redirect
