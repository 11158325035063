import styled from '@emotion/styled'

export const PageContainer = styled.div`
  padding: 8px 0 0;
`

export const MenuContainer = styled.div`
  position: relative;
  padding: 12px 16px;
`

export const FixedContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const FixedFlexContainer = styled(FixedContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
