import React, { ReactNode } from 'react'
import classNames from 'classnames'

import styles from './modal.scss'
import Portal, { PortalProps } from '../Portal'
import TransitionOut, { TransitionOutProps } from '../TransitionOut'

export interface ModalProps extends PortalProps, Pick<TransitionOutProps, 'onClosed'> {
  /**
   * 모달이 열려야 하는지에 대한 정보
   */
  isOpen: boolean
  /**
   * 모달의 overlay 를 클릭했을 때 닫기 위한 함수
   */
  onClose?: () => void
  /**
   * 모달의 zIndex
   */
  zIndex?: number
  /**
   * 모달이 열리고 닫힐 때의 animation duration
   */
  animationDurationMs?: number
  /**
   * 모달 overlay 의 배경 색상
   */
  overlayBackgroundColor?: string
  /**
   * 모달 안에 들어가는 컨텐츠의 포지션이 가운데인지에 대한 정보
   */
  isCenterMode?: boolean
  /**
   * 포탈없이 모달을 띄울 때 사용하는 상태
   */
  withoutPortal?: boolean
  /**
   * 모달 컨텐츠 영역의 className
   */
  bodyClassName?: string

  children?: ReactNode | TransitionOutProps['children']
}

const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  onClose,
  zIndex = 99,
  animationDurationMs = 300,
  overlayBackgroundColor,
  isCenterMode = true,
  selector,
  withoutPortal,
  bodyClassName,
  onClosed,
}) => {
  const body = (
    <TransitionOut isOpen={isOpen} duration={animationDurationMs} onClosed={onClosed}>
      {({ duration, outro }) => (
        <div
          className={classNames(styles.fullScreen, styles.modalBase, {
            [styles.open]: !outro,
            [styles.close]: outro,
            [styles.centerMode]: isCenterMode,
          })}
          style={{ animationDuration: `${duration}ms`, zIndex }}>
          <div
            className={classNames(styles.fullScreen, styles.modalOverlay)}
            onClick={onClose}
            style={overlayBackgroundColor ? { backgroundColor: overlayBackgroundColor } : undefined}
          />
          <div className={classNames(styles.modalCard, { [styles.centerMode]: isCenterMode }, bodyClassName)}>
            {typeof children === 'function' ? (children as any)({ duration, outro }) : children}
          </div>
        </div>
      )}
    </TransitionOut>
  )

  return withoutPortal ? body : <Portal selector={selector}>{body}</Portal>
}

export default Modal
