// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kf-1IsM-{bottom:0;left:0;right:0;background:none}.kf-1IsM- *{box-sizing:border-box}@keyframes kf-34zLZ{from{transform:translate3d(0, 1.5rem, 0)}to{transform:translate3d(0, 0, 0)}}@keyframes kf-1GQdI{from{transform:translate3d(0, 0, 0)}to{transform:translate3d(0, 1.5rem, 0)}}.kf-3vkXh{background:#fff;margin:0;padding:0;list-style:none;animation:kf-34zLZ 300ms}.kf-3vkXh.kf-jelw0{animation:kf-1GQdI 300ms}.kf-2hfMM{list-style:none;padding:0;margin:0;height:3.5rem;border-bottom:1px solid rgba(33,37,41,0.07);display:flex;align-items:center;justify-content:center;font-size:1rem;line-height:1.5rem;text-align:center;letter-spacing:-0.02em;cursor:pointer;transition:background-color 200ms;-webkit-tap-highlight-color:rgba(0,0,0,0);color:var(--gray900)}.kf-2hfMM:last-child{border:none}.kf-2hfMM:active{background-color:rgba(0,0,0,0.05)}.kf-2hfMM.kf-ydtTY{color:var(--red800)}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBodyOverrideStyle": "kf-1IsM-",
	"bottomSheetSelectionList": "kf-3vkXh",
	"fadeUp": "kf-34zLZ",
	"outro": "kf-jelw0",
	"fadeDown": "kf-1GQdI",
	"bottomSheetSelectionItem": "kf-2hfMM",
	"warning": "kf-ydtTY"
};
export default ___CSS_LOADER_EXPORT___;
