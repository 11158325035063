import React from 'react'

import { useEnvironment } from '@src/hooks/useEnvironment'

export default <Props,>(WrappedComponent: React.ComponentType<Props>) => {
  const EnhancedComponent: React.FC<Props> = (props) => {
    const os = useEnvironment()

    return <WrappedComponent os={os} {...props} />
  }

  return EnhancedComponent
}
