import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { vars } from '@seed-design/design-token'
import { AppScreen } from '@stackflow/plugin-basic-ui'
import { ActivityComponentType } from '@stackflow/react'

import { userInfoSelector } from '@src/selectors/user'
import { PageContainer, MenuContainer } from '@src/components/base/Container'
import { SectionTitle, MenuTitle } from '@src/components/base/Title'
import { InlineTextButton } from '@src/components/base/Button'
import { formatPhoneNumber } from '@src/utils'
import { countrySelector } from '@src/selectors/app'
import { useFlow } from '@src/router/Routes'

const MyAccountGlobalPage: ActivityComponentType = () => {
  const { push } = useFlow()
  const country = useSelector(countrySelector)
  const userInfo = useSelector(userInfoSelector)
  const { t } = useTranslation()

  const oauthProviderTitle = useMemo(() => {
    if (userInfo.oauthProvider === 'line') {
      return 'LINE Login'
    }

    if (userInfo.oauthProvider === 'apple') {
      return 'Apple Login'
    }

    return userInfo.oauthProvider
  }, [userInfo.oauthProvider])

  return (
    <AppScreen appBar={{ title: t('my_account.title') }}>
      <PageContainer>
        <section>
          <SectionTitle>{t('my_account.index.title.account_info')}</SectionTitle>
          {country !== 'JP' && (
            <MenuContainer>
              <MenuTitle>{t('my_account.index.menu.phone_number')}</MenuTitle>
              {userInfo.phone && <MenuItem>{formatPhoneNumber(userInfo.phone, country)}</MenuItem>}
              <InlineTextButton onClick={() => push('PhonePage', {})}>{t('common.change')}</InlineTextButton>
            </MenuContainer>
          )}
          {country === 'JP' &&
            userInfo.isPrepared &&
            (userInfo.oauthProvider && userInfo.oauthProviderId ? (
              <MenuContainer>
                <MenuTitle>{oauthProviderTitle}</MenuTitle>
                <MenuItem>{userInfo.oauthProviderId}</MenuItem>
              </MenuContainer>
            ) : (
              <MenuContainer>
                <MenuTitle>{t('my_account.index.menu.phone_number')}</MenuTitle>
                {userInfo.phone && <MenuItem>{formatPhoneNumber(userInfo.phone, country)}</MenuItem>}
                <InlineTextButton onClick={() => push('PhonePage', {})}>{t('common.change')}</InlineTextButton>
              </MenuContainer>
            ))}
        </section>
      </PageContainer>
    </AppScreen>
  )
}

export default MyAccountGlobalPage

const MenuItem = styled.div`
  margin: 8px 0 0;
  font-size: 0.875rem;
  color: ${vars.$scale.color.gray600};
`
