import React, { useMemo } from 'react'
import { Provider } from 'react-redux'

import { setStore } from '@src/sentry'

import configureStore, { InitialState } from './store'

interface StoreProviderProps {
  initialState: InitialState
}

const StoreProvider: React.FC<StoreProviderProps> = ({ initialState, children }) => {
  const store = useMemo(() => {
    const _store = configureStore(initialState)
    setStore(_store)

    return _store
  }, [initialState])

  return <Provider store={store}>{children}</Provider>
}

export default StoreProvider
