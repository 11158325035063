import { combineReducers, AnyAction } from 'redux'
import { connectRouter, LOCATION_CHANGE } from 'connected-react-router'
import { History } from 'history'

import app from './app'
import asyncState from './asyncState'
import region from './region'
import user from './user'
import phone from './phone'
import email from './email'
import article from './article'
import devTool from './devTool'

const rootReducer = (history: History) =>
  combineReducers({
    app,
    article,
    asyncState,
    region,
    user,
    phone,
    email,
    devTool,
    router: connectRouter(history),
  })

export type RootState = ReturnType<ReturnType<typeof rootReducer>>

const reducer = (history: History) => (state: RootState, action: AnyAction) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return rootReducer(history)({ ...state, asyncState: {} }, action)
    default:
      return rootReducer(history)(state, action)
  }
}

export default reducer
