import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@src/ducks'

export const articleSelector = createSelector(
  (state: RootState) => state.article,
  (d) => d
)

export const articleRegionsSelector = createSelector(articleSelector, (d) => d.articleRegions)

export const isH3UserSelector = createSelector(articleSelector, (d) => d.isH3User)
