// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kf-3oM7a{position:relative}.kf-ilPfN{left:50%;position:absolute;transform:translateX(-50%) translateY(-50%);top:50%;width:100%;height:100%}@keyframes kf-1R9lW{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.kf-1Rc3i{animation-duration:0.7s;animation-iteration-count:infinite;animation-name:kf-1R9lW;animation-timing-function:linear;display:inline-block}.kf-3e8-4{overflow:hidden}.kf-3e8-4.kf-2o239{transform:rotate(180deg)}@keyframes kf-2w7Vh{from{transform:rotate(60deg)}to{transform:rotate(205deg)}}@keyframes kf-3p8WK{from{transform:rotate(30deg)}to{transform:rotate(-115deg)}}.kf-Cuyi9{animation-direction:alternate;animation-duration:0.7s;animation-iteration-count:infinite;animation-name:kf-2w7Vh;animation-timing-function:cubic-bezier(0.25, 0.1, 0.5, 1);border-radius:50%;height:200%;transform:rotate(45deg);width:100%}.kf-2o239 .kf-Cuyi9{animation-name:kf-3p8WK}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circularProgress": "kf-3oM7a",
	"circularWrapper": "kf-ilPfN",
	"spinner": "kf-1Rc3i",
	"spinnerAnim": "kf-1R9lW",
	"circle": "kf-3e8-4",
	"circle2": "kf-2o239",
	"circleInner": "kf-Cuyi9",
	"animCircle1": "kf-2w7Vh",
	"animCircle2": "kf-3p8WK"
};
export default ___CSS_LOADER_EXPORT___;
