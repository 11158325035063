import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useActivity } from '@stackflow/react'

import { actions as history } from '@src/router/Routes'

export const useDispatchWithHistory = () => {
  const activity = useActivity()
  const isRoot = activity.zIndex === 0
  const dispatch = useDispatch()

  return useCallback((action) => {
    dispatch({ ...action, payload: { ...action.payload, history, isRoot } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) as typeof dispatch
}
