import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

export const ModalBodyStyle = css`
  position: relative;
  border: none;
  border-radius: 10px;
  padding: 20px;
  width: 272px;
  background-color: ${vars.$scale.color.gray50};
  text-align: center;
  word-break: keep-all;
`

export const ModalTitle = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
  color: ${vars.$scale.color.gray900};
`

export const ModalBody = styled.div<{ textAlign?: 'center' | 'initial' }>`
  margin: 6px 0 0;
  font-size: 1rem;
  color: ${vars.$scale.color.gray900};
  white-space: pre-line;
  text-align: ${(props) => props.textAlign ?? 'center'};
`

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0 0;
`
export const ModalButton = styled.div<{ active?: boolean }>`
  flex: 1;
  padding: 8px 0;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;

  ${(props) =>
    props.active
      ? css`
          color: ${vars.$static.color.staticWhite};
          background-color: ${vars.$scale.color.carrot500};
        `
      : css`
          border: 1px solid ${vars.$scale.color.gray400};
          color: ${vars.$scale.color.gray900};
          background-color: ${vars.$scale.color.gray50};
        `}

  :first-of-type {
    margin: 0 8px 0 0;
  }
`

export default {
  ModalBodyStyle,
  ModalTitle,
  ModalBody,
  ModalButtonContainer,
  ModalButton,
}
