import { SERVICE_KEY } from '@src/constants/api'

const initialValues = {
  accessToken: '',
  userAgent: '',
  acceptLanguage: '',
  deviceIdentity: ''
}

export default class Storage {
  private cacheStorage = { ...initialValues }

  constructor(inputValues?: typeof initialValues) {
    this.cacheStorage = inputValues || this.cacheStorage
  }

  clearAll() {
    this.cacheStorage = { ...initialValues }
  }

  setToken(accessToken: string) {
    this.cacheStorage.accessToken = accessToken
  }

  getToken(): string {
    return this.cacheStorage.accessToken
  }

  removeToken() {
    this.cacheStorage.accessToken = initialValues.accessToken
  }

  setUserAgent(userAgent: string) {
    this.cacheStorage.userAgent = userAgent
  }

  getUserAgent(): string {
    return this.cacheStorage.userAgent
  }

  removeUserAgent() {
    this.cacheStorage.userAgent = initialValues.userAgent
  }

  setAcceptLanguage(acceptLanguage: string) {
    this.cacheStorage.acceptLanguage = acceptLanguage
  }

  getAcceptLanguage(): string {
    return this.cacheStorage.acceptLanguage
  }

  setItemAtLocal(key: string, value: string) {
    window.localStorage.setItem(`${SERVICE_KEY}#${key}`, value)
  }

  getItemFromLocal(key: string) {
    return window.localStorage.getItem(`${SERVICE_KEY}#${key}`)
  }

  removeItemAtLocal(key: string) {
    window.localStorage.removeItem(`${SERVICE_KEY}#${key}`)
  }

  clearLocal() {
    window.localStorage.clear()
  }

  setDeviceIdentity(deviceIdentity: string) {
    this.cacheStorage.deviceIdentity = deviceIdentity
  }

  getDeviceIdentity(): string {
    return this.cacheStorage.deviceIdentity
  }
}
