export function focusOn(
  findElement: () => HTMLElement | undefined | null,
  options?: {
    delay?: number
    type?: HTMLInputElement['type']
  }
) {
  const $fakeInput = document.createElement('input')
  if (options?.type) {
    $fakeInput.setAttribute('type', options.type)
  }
  $fakeInput.style.fontSize = '16px'

  const $fakeForm = document.createElement('form')
  $fakeForm.style.position = 'fixed'
  $fakeForm.style.opacity = '0'
  $fakeForm.style.top = '0'
  $fakeForm.style.left = '0'
  $fakeForm.style.width = '100%'
  $fakeForm.action = '.'
  $fakeForm.onsubmit = (e) => {
    e.preventDefault()
  }
  $fakeForm.appendChild($fakeInput)

  document.body.appendChild($fakeForm)
  $fakeInput.focus()

  setTimeout(() => {
    const $el = findElement()
    $el?.focus()
    $fakeInput.remove()
    $fakeForm.remove()
  }, options?.delay ?? 300)
}
