import { createAction, createReducer } from '@reduxjs/toolkit'

import { storage } from '@src/api'
import { STORAGE_KEY_AD_ALLOWED, STORAGE_KEY_WEB_CRAWL_ALLOWED } from '@src/constants/api'
import { RegionInfo } from '@src/types/common'

export const updateUserState = createAction<any>('UPDATE_USER_STATE')
export const initStateFromMyAccount =
  createAction<{ email?: string; phone?: string; identityVerification?: identityVerificationType }>(
    'INIT_STATE_FROM_MY_ACCOUNT'
  )
export const initStateFromOtherSettings = createAction('INIT_STATE_FROM_OTHER_SETTINGS')
export const resetUser = createAction('RESET_USER')
export const reqUpdateWebCrawlAllowed = createAction<{ webCrawlAllowed: boolean }>('REQ_UPDATE_WEB_CRAWL_ALLOWED')
export const resUpdateWebCrawlAllowed = createAction<{ webCrawlAllowed: boolean }>('RES_UPDATE_WEB_CRAWL_ALLOWED')
export const reqUpdateAdAllowed = createAction<{ adAllowed: boolean }>('REQ_UPDATE_AD_ALLOWED')
export const resUpdateAdAllowed = createAction<{ adAllowed: boolean }>('RES_UPDATE_AD_ALLOWED')
export const reqCheckinRegions = createAction('REQ_CHECKIN_REGIONS')
export const resCheckinRegions = createAction<{ checkinRegions: RegionInfo[] }>('RES_CHECKIN_REGIONS')

type identityVerificationType = {
  verifiedAt?: string
  birthday?: string
  phone?: string
}

export interface State {
  phone: string
  phoneVerifiedAt: string
  email: string
  emailVerifiedAt: string
  authToken: string
  id: string
  checkinRegions: RegionInfo[]
  userExtend: { [key: string]: any; webCrawlAllowed: boolean; adAllowed: boolean }
  identityVerification?: identityVerificationType
  oauthProvider?: string
  oauthProviderId?: string
  isPrepared: boolean
}

export const INITIAL_STATE: State = {
  phone: '',
  phoneVerifiedAt: '',
  email: '',
  emailVerifiedAt: '',
  authToken: '',
  id: '',
  checkinRegions: [],
  userExtend: {
    webCrawlAllowed: JSON.parse(storage.getItemFromLocal(STORAGE_KEY_WEB_CRAWL_ALLOWED) || 'true'),
    adAllowed: JSON.parse(storage.getItemFromLocal(STORAGE_KEY_AD_ALLOWED) || 'true'),
  },
  identityVerification: {},
  oauthProvider: '',
  oauthProviderId: '',
  isPrepared: false,
}

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(resetUser, () => INITIAL_STATE)
    .addCase(updateUserState, (state, { payload }) => {
      Object.assign(state, payload)
    })
    .addCase(initStateFromMyAccount, (state, { payload }) => {
      state.email = payload.email || state.email
      state.phone = payload.phone || state.phone
      state.identityVerification = payload.identityVerification || state.identityVerification
    })
    .addCase(reqUpdateWebCrawlAllowed, (state, { payload }) => {
      state.userExtend.webCrawlAllowed = payload.webCrawlAllowed
    })
    .addCase(resUpdateWebCrawlAllowed, (state, { payload }) => {
      state.userExtend.webCrawlAllowed = payload.webCrawlAllowed
    })
    .addCase(reqUpdateAdAllowed, (state, { payload }) => {
      state.userExtend.adAllowed = payload.adAllowed
    })
    .addCase(resUpdateAdAllowed, (state, { payload }) => {
      state.userExtend.adAllowed = payload.adAllowed
    })
    .addCase(resCheckinRegions, (state, { payload }) => {
      state.checkinRegions = payload.checkinRegions
    })
})
