import { map, catchError } from 'rxjs/operators'

import { throwApiError } from '@src/utils'

import Request from './request'

export const API_NAMESPACE = 'karrotauth'
export const PATH_SEND_EMAIL_CODE = '/v2/auth/u/email/register/sendcode'
export const PATH_REGISTER_EMAIL = '/v2/auth/u/email/register'

class Karrotauth extends Request {
  constructor(baseUrl: string, baseUrlKey: string) {
    super(baseUrl, baseUrlKey)

    this.setTokenHeader()
    this.setAcceptLanguageHeader()
    this.setDeviceIdentityHeader()
  }

  public reqEmailCode$(userId: string, email: string) {
    return this.post$(PATH_SEND_EMAIL_CODE, { user_id: userId, email }).pipe(
      map(({ data }) => data),
      catchError((err) => throwApiError({ err, origin: API_NAMESPACE, api: 'reqEmailCode$' }))
    )
  }

  public registerEmail$(userId: string, email: string, code: string) {
    return this.post$(PATH_REGISTER_EMAIL, { user_id: userId, email, vrf_code: code }).pipe(
      map(({ data }) => data),
      catchError((err) => throwApiError({ err, origin: API_NAMESPACE, api: 'registerEmail$' }))
    )
  }
}

export default Karrotauth
