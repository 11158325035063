import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { vars } from '@seed-design/design-token'

import { FixedFlexContainer } from '@src/components/base/Container'
import { OutlineTextButton } from '@src/components/base/Button'
import { useEnhancedRouter } from '@src/hooks/useEnhancedRouter'

const ErrorPage: React.FC = () => {
  const { t } = useTranslation()
  const { close } = useEnhancedRouter()

  return (
    <FixedFlexContainer>
      <Message>{t('common.error_occured')}</Message>
      <OutlineTextButtonBold onClick={close}>{t('common.close')}</OutlineTextButtonBold>
    </FixedFlexContainer>
  )
}

export default ErrorPage

const Message = styled.div`
  margin: 0 0 1rem;
  font-size: 1rem;
  color: ${vars.$scale.color.gray600};
  text-align: center;
  white-space: pre-line;
`

const OutlineTextButtonBold = styled(OutlineTextButton)`
  font-weight: bold;
`
