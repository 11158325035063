import { AnyAction } from 'redux'

import { AsyncStateInput, AsyncState } from '@src/types/asyncState'
import { PENDING, FULFILLED, REJECTED } from '@src/constants/api'

export const UPDATE_ASYNC_STATE = 'UPDATE_ASYNC_STATE'

export const updateAsyncState = (target: string, state: AsyncStateInput) => ({
  type: UPDATE_ASYNC_STATE,
  target,
  state,
})

const initialAsyncState = {
  pending: false,
  fulfilled: false,
  rejected: false,
}

export const asyncState = (state?: AsyncStateInput): AsyncState => {
  switch (state) {
    case PENDING:
    case FULFILLED:
    case REJECTED:
      return {
        ...initialAsyncState,
        [state]: true,
      }
    default:
      return initialAsyncState
  }
}

export interface State {
  [action: string]: ReturnType<typeof asyncState>
}

export const INITIAL_STATE: State = {}

export default (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case UPDATE_ASYNC_STATE: {
      return {
        ...state,
        [action.target]: asyncState(action.state),
      }
    }
    default:
      return state
  }
}
