import React, { ImgHTMLAttributes, memo } from 'react'

import { useFallbackImage } from '../../hooks/useFallbackImage'
import styles from './profile_avatar.scss'

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  fallbackSrc?: string
  size: number | string
  borderRadius?: string
  src?: string
}

const ProfileAvatar = ({ size, src, fallbackSrc, onClick, borderRadius, children, ...props }: Props) => {
  const { profileSrc, handleLoadError } = useFallbackImage({ src, fallbackSrc })

  return (
    <div className={styles.profileAvatarWrapper} style={{ width: size, height: size, borderRadius }}>
      <img {...props} src={profileSrc} onError={handleLoadError} className={styles.image} style={{ borderRadius }} />
      <div className={styles.border} style={{ borderRadius }} />
      {children}
    </div>
  )
}

export default memo(ProfileAvatar)
