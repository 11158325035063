import { Iframe, useDialogOpened } from '@daangn/transfer-identification-sdk'
import { BottomSheet } from '@stackflow/plugin-basic-ui'
import type { ActivityComponentType } from '@stackflow/react'
import React from 'react'

import { bridge } from '@src/bridge'
import { useFlow } from '@src/router/Routes'
import { IS_PROD } from '@src/config'

const ActivityBottomSheetOnlyTerms: ActivityComponentType = () => {
  const { pop } = useFlow()

  useDialogOpened(() => {
    pop()
  })

  return (
    <BottomSheet>
      <Iframe
        bridge={bridge}
        url={
          IS_PROD
            ? 'https://terms.karrotwebview.com/pay_identity'
            : 'https://terms.alpha.karrotwebview.com/pay_identity'
        }
      />
    </BottomSheet>
  )
}

export default ActivityBottomSheetOnlyTerms
