import { createSelector } from '@reduxjs/toolkit'

import { DEFAULT_COUNTRY_KEY } from '@src/constants/api'
import { RootState } from '@src/ducks'

export const appSelector = createSelector(
  (state: RootState) => state.app,
  (d) => d
)

export const countrySelector = createSelector(appSelector, (d) => d.country || DEFAULT_COUNTRY_KEY)
