import { installKarrotBridgeDriver, makeKarrotBridge } from '@daangn/karrotbridge'

const { driver } = installKarrotBridgeDriver()
export const bridge = makeKarrotBridge({ driver })

import { IS_DESKTOP } from '@src/config'

export const logAnalyticsEvent = (data: { eventName: string; params?: Record<string, unknown> }): void => {
  const event = {
    analytics: {
      target: 'KARROT' as const,
      name: data.eventName,
      params: data.params ? JSON.stringify(data.params) : '',
    },
  }

  if (IS_DESKTOP) {
    console.log(`analytics event called: ${JSON.stringify(event)}`)
    return
  }
  bridge.logEvent(event)
}
