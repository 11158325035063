import React, { MouseEvent, ReactNode } from 'react'
import classNames from 'classnames'

import styles from './bottom_sheet.scss'
import Modal, { ModalProps } from '../Modal'

interface Sheet {
  name: ReactNode
  warning?: boolean
  onClick?: (e: MouseEvent<HTMLLIElement>) => void
}

export interface BottomSheetProps<T = Sheet> extends Omit<ModalProps, 'isCenterMode' | 'children'> {
  /**
   * sheet 리스트
   */
  sheets: T[]
}

function BottomSheet<SheetType extends Sheet>({ sheets, isOpen, onClose, ...modalProps }: BottomSheetProps<SheetType>) {
  return (
    <Modal
      {...modalProps}
      isOpen={isOpen}
      isCenterMode={false}
      zIndex={9999}
      onClose={onClose}
      bodyClassName={classNames(styles.modalBodyOverrideStyle, modalProps.bodyClassName)}>
      {({ duration, outro }) => (
        <ul
          className={classNames(styles.bottomSheetSelectionList, {
            [styles.outro]: outro,
          })}
          style={{
            animationDuration: `${duration}ms`,
          }}>
          {sheets.map(({ name, warning, onClick }, idx) => (
            <li
              key={idx}
              onClick={onClick}
              className={classNames(styles.bottomSheetSelectionItem, {
                [styles.warning]: warning,
              })}>
              {name}
            </li>
          ))}
        </ul>
      )}
    </Modal>
  )
}

export default BottomSheet
