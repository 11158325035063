import { createHashHistory } from 'history'
import { applyMiddleware, compose, createStore, Action, Store } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { createEpicMiddleware } from 'redux-observable'

import sentry from '@src/middlewares/sentry'
import rootReducer, { RootState } from '@src/ducks'
import rootEpics, { EpicDependencies } from '@src/epics'
import logger from '@src/middlewares/logger'
import report from '@src/middlewares/report'
import { hoian, storage, karrotauth } from '@src/api'
import i18n from '@src/i18n'
import { Info } from '@src/types/common'
import { bridge } from '@src/bridge'

export type InitialState = Info & { region: any }

let store: Store | null = null
let epic: any

export const history = createHashHistory()

const createMiddleware = () => {
  epic = createEpicMiddleware<Action, Action, RootState, EpicDependencies>({
    dependencies: {
      hoian,
      storage,
      bridge,
      karrotauth,
      t: i18n.getFixedT(null, 'user_settings'),
    },
  })
  const router = routerMiddleware(history)
  const middlewares = [router, epic, report, sentry]

  if (process.env.NODE_ENV === 'development') {
    return applyMiddleware(logger, ...middlewares)
  }

  return applyMiddleware(...middlewares)
}

export default (initialState: InitialState) => {
  const middlewares = createMiddleware()
  store = createStore(rootReducer(history) as any, initialState, compose(middlewares))

  epic.run(rootEpics)

  return store
}
