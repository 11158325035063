import React from 'react'
import { useSelector } from 'react-redux'

import Redirect from '@src/router/Redirect'
import { isLoginSelector } from '@src/selectors/user'

export default <Props,>(WrappedComponent: React.ComponentType<Props>) => {
  const EnhancedComponent: React.FC<Props> = (props) => {
    const isLogin = useSelector(isLoginSelector)

    if (!isLogin) {
      return <Redirect to="NeedLoginPage" />
    }

    return <WrappedComponent {...props} />
  }

  return EnhancedComponent
}
