import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AppScreen } from '@stackflow/plugin-basic-ui'
import { AlertDialog, DialogContainer } from '@daangn/sprout-components-dialog'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useTranslation } from 'react-i18next'
import { BoxButton } from '@daangn/sprout-components-button'

import identificationStart from '@src/assets/images/identification_start.svg'
import IconBack from '@src/components/svg/IconBack'
import { IS_PROD } from '@src/config'
import { useFlow } from '@src/router/Routes'
import { fetchWithAuth } from '@src/api/fetchWithAuth'
import { logAnalyticsEvent } from '@src/bridge'
import { IDENTIFICATION_BASE_URL } from '@src/constants/api'

enum StepStatus {
  MOVE_IDENTIFICATION_MODULE = 'MOVE_IDENTIFICATION_MODULE',
  CHECK_UPDATABLE_PHONE_NUM = 'CHECK_UPDATABLE_PHONE_NUM',
  UPDATE_PHONE_NUM = 'UPDATE_PHONE_NUM',
  SHOW_ERROR_DIALOG = 'SHOW_ERROR_DIALOG',
  REPLACE_COMPLETE_PAGE = 'REPLACE_COMPLETE_PAGE',
  NO_OP = 'NO_OP',
}

function isMoreThanFiveMinutesAgo(payloadTime: string | number | Date): boolean {
  // 현재 시각
  const now = new Date()

  // payload 시각을 Date 객체로 변환
  const payloadDate = new Date(payloadTime)

  // 두 시각의 차이를 밀리초로 계산
  const differenceInMs = now.getTime() - payloadDate.getTime()

  // 5분을 밀리초로 변환 (5 * 60 * 1000)
  const fiveMinutesInMs = 5 * 60 * 1000

  // 차이가 5분 이상인지 확인
  return differenceInMs >= fiveMinutesInMs
}

const USER_BASE_URL = IS_PROD ? 'https://user.kr.karrotmarket.com' : 'https://user.alpha.kr.karrotmarket.com'

const API_ENDPOINT_URL = {
  IDENTIFICATION: `${IDENTIFICATION_BASE_URL}/identification/v2/karrot-users/me/kr/identification`,
  CHECK_UPDATABLE_PHONE_NUM: `${USER_BASE_URL}/user/v2/karrot-users/me/phone-number/check-updatable`,
  UPDATE_PHONE_NUM: `${USER_BASE_URL}/user/v2/karrot-users/me/phone-number/verifications/karrot-user-identification`,
}

const IDENTIFICATION_NATIVE_MODULE_SCHEME = IS_PROD
  ? 'karrot://identification?type=change_phone_number&referrer=beagle&show_complete=true'
  : ' karrot.alpha://identification?type=change_phone_number&referrer=beagle&show_complete=true'

const CORPORATE_PHONE_GUIDE_SCHEME = IS_PROD
  ? 'karrot://minikarrot/router?remote=https%3A%2F%2Fcs.kr.karrotmarket.com%2Fwv%2Ffaqs%2F11442&navbar=false&scrollable=false'
  : 'karrot.alpha://minikarrot/router?remote=https%3A%2F%2Fcs.kr.karrotmarket.com%2Fwv%2Ffaqs%2F11442&navbar=false&scrollable=false'

const IdentificationPageForPhoneNumberChange = () => {
  const { t } = useTranslation()
  const { replace } = useFlow()
  const isProceeding = useRef(false)

  const [requestStatus, setRequestStatus] = useState<'idle' | 'pending' | 'move_native' | 'complete' | 'failed'>('idle')
  const [showDialogue, setShouldDialogueOpen] = useState(false)
  const [error, setError] = useState({
    title: '',
    description: '',
  })

  const identificationRequest = useCallback(async () => {
    setRequestStatus('pending')
    const identificationRes = await fetchWithAuth(API_ENDPOINT_URL['IDENTIFICATION'])
    if (!identificationRes.ok) {
      return StepStatus.MOVE_IDENTIFICATION_MODULE
    }
    const identificationData = await identificationRes.json()
    if (isMoreThanFiveMinutesAgo(identificationData.verify_time)) {
      return StepStatus.MOVE_IDENTIFICATION_MODULE
    }
    return StepStatus.CHECK_UPDATABLE_PHONE_NUM
  }, [])

  const identificationRequestAfterNativeModule = useCallback(async () => {
    const identificationRes = await fetchWithAuth(API_ENDPOINT_URL['IDENTIFICATION'])
    if (!identificationRes.ok) {
      setRequestStatus('failed')
      console.log('identificationRes: ', identificationRes)
      return StepStatus.NO_OP
    }
    const identificationData = await identificationRes.json()
    if (isMoreThanFiveMinutesAgo(identificationData.verify_time)) {
      setRequestStatus('failed')
      console.log('identificationRes: ', identificationRes)
      return StepStatus.NO_OP
    }
    return StepStatus.CHECK_UPDATABLE_PHONE_NUM
  }, [])

  const checkUpdatablePhoneNumber = useCallback(async () => {
    const checkUpdatablePhoneNumberRes = await fetchWithAuth(API_ENDPOINT_URL['CHECK_UPDATABLE_PHONE_NUM'])
    const checkUpdatablePhoneNumberData = await checkUpdatablePhoneNumberRes.json()
    if (
      'display_type' in checkUpdatablePhoneNumberData &&
      checkUpdatablePhoneNumberData.display_type === 'DISPLAY_TYPE_DIALOG'
    ) {
      setError({
        title: checkUpdatablePhoneNumberData.title.text,
        description: checkUpdatablePhoneNumberData.message.text,
      })
      setRequestStatus('failed')
      return StepStatus.SHOW_ERROR_DIALOG
    }
    return StepStatus.UPDATE_PHONE_NUM
  }, [])

  const updatePhoneNumber = useCallback(async () => {
    const updatePhoneNumberRes = await fetchWithAuth(API_ENDPOINT_URL['UPDATE_PHONE_NUM'], {
      method: 'PUT',
    })
    const updatePhoneNumberData = await updatePhoneNumberRes.json()

    if ('display_type' in updatePhoneNumberData && updatePhoneNumberData.display_type === 'DISPLAY_TYPE_DIALOG') {
      setError({
        title: updatePhoneNumberData.title.text,
        description: updatePhoneNumberData.message.text,
      })
      setRequestStatus('failed')
      return StepStatus.SHOW_ERROR_DIALOG
    }

    setRequestStatus('complete')
    return StepStatus.REPLACE_COMPLETE_PAGE
  }, [])

  const proceedChangePhoneNumber = useCallback(async () => {
    try {
      console.log('proceedChangePhoneNumber')
      const stepFirst = await identificationRequest()

      if (stepFirst === StepStatus.MOVE_IDENTIFICATION_MODULE) {
        setRequestStatus('move_native')
        window.location.href = IDENTIFICATION_NATIVE_MODULE_SCHEME
        return
      }

      const stepSecond = await checkUpdatablePhoneNumber()
      if (stepSecond === StepStatus.SHOW_ERROR_DIALOG) {
        setShouldDialogueOpen(true)
        return
      }

      const stepThird = await updatePhoneNumber()
      if (stepThird === StepStatus.SHOW_ERROR_DIALOG) {
        setShouldDialogueOpen(true)
        return
      }

      if (stepThird === StepStatus.REPLACE_COMPLETE_PAGE) {
        replace('IdentificationPageForPhoneNumberComplete', {})
      }

      setRequestStatus('failed')
      setError({
        title: t('my_account.identity_verification.update_phone_number_with_identification_failed.title'),
        description: t('my_account.identity_verification.update_phone_number_with_identification_failed.description'),
      })
      setShouldDialogueOpen(true)
    } catch (e) {
      console.error(e)
      setRequestStatus('failed')
      setError({
        title: t('my_account.identity_verification.update_phone_number_with_identification_failed.title'),
        description: t('my_account.identity_verification.update_phone_number_with_identification_failed.description'),
      })
      setShouldDialogueOpen(true)
    }
  }, [checkUpdatablePhoneNumber, identificationRequest, replace, t, updatePhoneNumber])

  const proceedChangePhoneNumberAfterNative = useCallback(async () => {
    try {
      isProceeding.current = true
      const stepFirst = await identificationRequestAfterNativeModule()

      if (stepFirst === StepStatus.NO_OP) {
        isProceeding.current = false
        return
      }

      const stepSecond = await checkUpdatablePhoneNumber()
      if (stepSecond === StepStatus.SHOW_ERROR_DIALOG) {
        isProceeding.current = false
        setShouldDialogueOpen(true)
        return
      }

      const stepThird = await updatePhoneNumber()
      if (stepThird === StepStatus.SHOW_ERROR_DIALOG) {
        isProceeding.current = false
        setShouldDialogueOpen(true)
        return
      }

      if (stepThird === StepStatus.REPLACE_COMPLETE_PAGE) {
        isProceeding.current = false
        logAnalyticsEvent({
          eventName: 'client_shown_app_screen_v1',
          params: {
            screen_class_name: 'PhonePage',
            screen_name: 'update_phone_complete',
            service_name: 'common',
          },
        })
        replace('IdentificationPageForPhoneNumberComplete', {})
        return
      }

      setRequestStatus('failed')
      setError({
        title: t('my_account.identity_verification.update_phone_number_with_identification_failed.title'),
        description: t('my_account.identity_verification.update_phone_number_with_identification_failed.description'),
      })
      setShouldDialogueOpen(true)
      isProceeding.current = false
    } catch (e) {
      console.error(e)
      setRequestStatus('failed')
      setError({
        title: t('my_account.identity_verification.update_phone_number_with_identification_failed.title'),
        description: t('my_account.identity_verification.update_phone_number_with_identification_failed.description'),
      })
      setShouldDialogueOpen(true)
      isProceeding.current = false
    }
  }, [checkUpdatablePhoneNumber, identificationRequestAfterNativeModule, replace, t, updatePhoneNumber])

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible' && requestStatus === 'move_native' && !isProceeding.current) {
        console.log('proceedChangePhoneNumberAfterNative')
        await proceedChangePhoneNumberAfterNative()
      }
    }
    window.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [proceedChangePhoneNumberAfterNative, replace, requestStatus, t])

  return (
    <AppScreen
      appBar={{
        backButton: {
          renderIcon: () => <IconBack />,
          onClick: () => {},
        },
        border: false,
      }}>
      <Container>
        <TopContainer>
          <TitleMessage>
            {t('my_account.identity_verification.update_phone_number_with_identification.title')}
          </TitleMessage>
          <Description>
            {t('my_account.identity_verification.update_phone_number_with_identification.description')}
          </Description>
        </TopContainer>
        <ImageContainer>
          <CenterImage src={identificationStart} alt="Icon for identification" />
        </ImageContainer>
        <BottomContainer>
          <CorporatePhoneGuideContainer>
            <CorporatePhoneGuideMessage
              onClick={() => {
                window.location.href = CORPORATE_PHONE_GUIDE_SCHEME
              }}>
              {t('my_account.identity_verification.update_phone_number_with_identification.guide_for_corporate_phone')}
            </CorporatePhoneGuideMessage>
          </CorporatePhoneGuideContainer>
          <BoxButton
            size="xlarge"
            isLoading={requestStatus === 'pending' || requestStatus === 'move_native'}
            isDisabled={requestStatus === 'pending' || requestStatus === 'move_native'}
            onClick={async () => {
              logAnalyticsEvent({ eventName: 'client_clicked_update_phone_by_identification_button_v1' })
              await proceedChangePhoneNumber()
            }}>
            {t('my_account.identity_verification.update_phone_number_with_identification.primary_action')}
          </BoxButton>
        </BottomContainer>
        <DialogContainer
          onOutsideClick={() => {
            setShouldDialogueOpen(false)
          }}>
          {showDialogue && (
            <AlertDialog
              title={error.title}
              description={error.description}
              onPrimaryAction={() => {
                setShouldDialogueOpen(false)
              }}
              primaryActionLabel={t('common.confirm')}
              maxWidth="80%"
              UNSAFE_style={{ whiteSpace: 'pre-line' }}
            />
          )}
        </DialogContainer>
      </Container>
    </AppScreen>
  )
}

export default IdentificationPageForPhoneNumberChange

const Container = styled.div`
  padding: 4px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 140px;
`

const TitleMessage = styled.h2`
  font-size: 1.5rem;
  color: ${vars.$scale.color.gray900};
  font-weight: 700;
  white-space: pre-wrap;
  line-height: 135%;
`

const Description = styled.p`
  font-size: 1rem;
  color: ${vars.$scale.color.gray900};
  font-weight: 400;
  margin-top: 0.375rem;
  white-space: pre-wrap;
  line-height: 135%;
`

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
`

const CorporatePhoneGuideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1.375rem;
`

const CorporatePhoneGuideMessage = styled.p`
  font-size: 0.875rem;
  color: ${vars.$scale.color.gray600};
  white-space: pre-wrap;
  line-height: 135%;
  font-weight: 700;
  text-decoration: underline;
`

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-bottom: calc(12px + env(safe-area-inset-bottom, 0px));
  padding-bottom: calc(12px + constant(safe-area-inset-bottom, 0px));
`

const CenterImage = styled.img`
  width: 70%;
  height: auto;
`
