import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { useCallback } from 'react'

import { IS_DESKTOP, IS_NATIVE, APP_URLS } from '@src/config'
import { countrySelector } from '@src/selectors/app'
import { bridge } from '@src/bridge'

interface RouterParameters {
  remote?: string
  path?: string
  navbar?: boolean
  navbarTitle?: string
  scrollable?: boolean
}

export const useEnhancedRouter = () => {
  const history = useHistory()
  const country = useSelector(countrySelector)

  const push = useCallback(
    ({ remote, path = '/', navbar = true, navbarTitle, scrollable }: RouterParameters) => {
      if (IS_DESKTOP) {
        remote ? (window.location.href = remote) : history.push(path)
        return
      }

      if (!IS_NATIVE) {
        remote = remote || window.location.origin
      }

      if (remote) {
        bridge.pushRouter({
          router: {
            remote,
            path,
            navbar,
            navbarTitle,
            scrollable,
          },
        })
        return
      }

      bridge.pushRouter({
        router: {
          app: APP_URLS[country].jobs,
          path,
          navbar,
          navbarTitle,
          scrollable,
        },
      })
    },
    [history, country]
  )

  const open = useCallback(
    ({ remote, path = '/', navbar = true, navbarTitle, scrollable }: RouterParameters) => {
      if (IS_DESKTOP) {
        remote ? (window.location.href = remote) : history.push(path)
        return
      }

      if (!IS_NATIVE) {
        remote = remote || window.location.origin
      }

      if (remote) {
        bridge.pushRouterWithPresent({
          router: {
            remote,
            path,
            navbar,
            navbarTitle,
            scrollable,
          },
        })
        return
      }

      bridge.pushRouterWithPresent({
        router: {
          app: APP_URLS[country].jobs,
          path,
          navbar,
          navbarTitle,
          scrollable,
        },
      })
    },
    [history, country]
  )

  const close = useCallback(() => {
    if (IS_DESKTOP) {
      alert('window close event trigger')
      return
    }

    bridge.closeRouter({})
  }, [])

  const pop = useCallback(() => {
    if (IS_DESKTOP) {
      history.goBack()
      return
    }

    bridge.closeRouter({})
  }, [history])

  return { push, close, open, pop }
}
