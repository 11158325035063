import React, { useRef, useEffect, useState, useLayoutEffect } from 'react'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { vars } from '@seed-design/design-token'
import { AppScreen } from '@stackflow/plugin-basic-ui'
import { useStack } from '@stackflow/react'

import FullSizeModal from '@src/components/base/Modal/FullSizeModal'
import { reqPhoneCode, reqUpdatePhoneNumber, resetPhoneState } from '@src/ducks/phone'
import { validatePhoneNumber, validatePhoneValidationCode } from '@src/utils/validation'
import { phoneInfoSelector } from '@src/selectors/phone'
import {
  ModalBodyStyle,
  ModalTitle,
  ModalBody,
  ModalButtonContainer,
  ModalButton,
} from '@src/components/base/Modal/Modal'
import Timer from '@src/components/base/Timer'
import { useActionState } from '@src/hooks/useActionState'
import Loader from '@src/components/base/Loader'
import { formatPhoneNumber, revertPhoneNumber } from '@src/utils'
import { useCheckTimer } from '@src/hooks/useCheckTimer'
import { bridge } from '@src/bridge'
import { useDispatchWithHistory } from '@src/hooks/useDispatchWithHistory'
import { focusOn } from '@src/utils/focus'
import useModalState from '@src/hooks/useModalState'
import { countrySelector } from '@src/selectors/app'
import ChevronRight from '@src/components/svg/ArrowRight'
import { SCHEME_PREFIX } from '@src/config'
import withOS from '@src/hocs/withOS'
import { body2, subtitle2 } from '@src/styles/text'
import IconBack from '@src/components/svg/IconBack'

const NAVBAR_HEIGHT_REM = '3.5rem'
const BOTTOM_BUTTON_HORIZONTAL_MARGIN_PX = '16px'
const BOTTOM_BUTTON_HEIGHT_PX = '55px'
const __DANGEROUS_COVER_UNKNOWN_MARGIN_PX = '12px'

interface FormValues {
  phone: string
  code: string
}

const PhonePage: React.FC = (props) => {
  const { globalTransitionState } = useStack()
  const { t, i18n } = useTranslation()
  const country = useSelector(countrySelector)
  const phoneInfo = useSelector(phoneInfoSelector)
  const dispatch = useDispatchWithHistory()
  const formik = useFormik<FormValues>({
    initialValues: { phone: '', code: '' },
    onSubmit: () => {},
    validateOnChange: false,
  })
  const timerRef = useRef(null)
  const phoneRef = useRef<HTMLInputElement>(null)
  const codeRef = useRef<HTMLInputElement>(null)
  const [enhancedReqPhoneCode, codeAsyncState] = useActionState(reqPhoneCode)
  const [enhancedReqUpdatePhoneNumber, phoneAsyncState] = useActionState(reqUpdatePhoneNumber)
  const [isModalOpen, setModalOpen, setModalClose] = useModalState(false)
  const { isEnd, triggerTimer } = useCheckTimer()

  const phoneInfoErrorMessage = () => {
    const translatedErrorMessageWithErrorCode = t(`hoian.errors.${phoneInfo.phoneCodeErrorCode}`)
    if (phoneInfo.phoneCodeErrorCode !== 'too_many_sms_verification_code') {
      return translatedErrorMessageWithErrorCode
    }

    if (!phoneInfo || !phoneInfo.phoneCodeMessage) {
      return translatedErrorMessageWithErrorCode
    }

    if (!i18n.language.includes('ja')) {
      const numbers = phoneInfo.phoneCodeMessage.match(/\d+/)
      if (!numbers) return translatedErrorMessageWithErrorCode

      const verificationCount = numbers[0]
      return t(`hoian.errors.${phoneInfo.phoneCodeErrorCode}`, { verificationCount })
    }

    const dividedTexts = phoneInfo.phoneCodeMessage.match(/[^\uff10-\uff19]/g)
    if (!dividedTexts) return translatedErrorMessageWithErrorCode
    const verificationCount = dividedTexts.splice(14).slice(0, -10).join('')
    return t(`hoian.errors.${phoneInfo.phoneCodeErrorCode}`, { verificationCount })
  }

  const [keyboardHeight, setKeyboardHeight] = useState(window.outerHeight - window.visualViewport.height)
  const [viewportHeight, setViewportHeight] = useState(-1)
  const [translateY, setTranslateY] = useState(0)
  const [keyboardActivateStatus, setKeyboardActivateStatus] = useState<'idle' | 'by-top' | 'by-bottom'>('idle')

  useEffect(() => {
    const phoneInput = phoneRef.current
    if (!phoneInput) return

    const decreaseViewportHeight = () => {
      setKeyboardActivateStatus('by-top')
    }
    const increaseViewportHeight = () => {
      setTimeout(() => {
        setKeyboardActivateStatus('idle')
      }, 0)
    }

    phoneInput.addEventListener('focus', decreaseViewportHeight)
    phoneInput.addEventListener('blur', increaseViewportHeight)

    return () => {
      if (!phoneInput) return
      phoneInput.removeEventListener('focus', decreaseViewportHeight)
      phoneInput.removeEventListener('blur', increaseViewportHeight)
    }
  }, [])

  useEffect(() => {
    const code = codeRef.current
    if (!code) return

    const decreaseViewportHeight = () => {
      setKeyboardActivateStatus('by-bottom')
    }
    const increaseViewportHeight = () => {
      setKeyboardActivateStatus('idle')
    }

    code.addEventListener('focus', decreaseViewportHeight)
    code.addEventListener('blur', increaseViewportHeight)

    return () => {
      if (!code) return
      code.removeEventListener('focus', decreaseViewportHeight)
      code.removeEventListener('blur', increaseViewportHeight)
    }
  }, [])

  useEffect(() => {
    const resizeHandler = () => {
      setViewportHeight(window.visualViewport.height)
      const keyboardHeight = window.outerHeight - window.visualViewport.height
      setKeyboardHeight(keyboardHeight)
    }
    window.visualViewport.addEventListener('resize', resizeHandler)
    return () => {
      window.visualViewport.removeEventListener('resize', resizeHandler)
    }
  }, [])

  useLayoutEffect(() => {
    const codeInput = codeRef.current

    if (!codeInput) return

    const handleFocus = () => {
      setKeyboardActivateStatus('by-bottom')
    }

    const handleBlur = () => {
      setTimeout(() => {
        setKeyboardActivateStatus('idle')
      }, 0)
    }

    codeInput.addEventListener('focus', handleFocus)
    codeInput.addEventListener('blur', handleBlur)

    return () => {
      if (!codeInput) return
      codeInput.removeEventListener('focus', handleFocus)
      codeInput.removeEventListener('blur', handleBlur)
    }
  }, [])

  useEffect(() => {
    if (globalTransitionState === 'idle' && (props as { os: 'Android' | 'Cupertino' }).os === 'Android') {
      focusOn(() => phoneRef.current, {
        delay: 100,
        type: 'tel',
      })
    }
  }, [globalTransitionState, props])

  useEffect(() => {
    if (codeAsyncState.fulfilled) {
      focusOn(() => codeRef.current, {
        type: 'number',
      })
    }
  }, [codeAsyncState])

  useEffect(() => {
    const handleScroll = () => {
      const isKeyboardActivated = window.scrollY !== 0

      // public 디렉터리의 index.html 파일에 style 로 정의해두었다.
      const safeAreaEnv = getComputedStyle(document.documentElement).getPropertyValue('--safe-area-top-env')
      const safeAreaConstant = getComputedStyle(document.documentElement).getPropertyValue('--safe-area-top-constant')

      // iOS 11.2 버전 이상에서는 ##px 이라고 표시한다.
      if (safeAreaEnv.includes('px')) {
        setTranslateY(isKeyboardActivated ? keyboardHeight - window.scrollY - parseInt(safeAreaEnv.slice(0, -2)) : 0)
        return
      }

      // iOS 11.0 버전 이하에서는 ##px 이라고 표시한다.
      if (safeAreaConstant.includes('px')) {
        setTranslateY(
          isKeyboardActivated ? keyboardHeight - window.scrollY - parseInt(safeAreaConstant.slice(0, -2)) : 0
        )
        return
      }

      // fallback 코드이지만 호출되면 스타일이 어색하나 버튼 클릭이 불가능하지 않음
      setTranslateY(isKeyboardActivated ? keyboardHeight - window.scrollY : 0)
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [keyboardHeight])

  useEffect(() => {
    return () => {
      dispatch(resetPhoneState)
    }
  }, [dispatch])

  const validatePhone = (phone: string) => {
    let error

    if (!validatePhoneNumber(phone)) {
      error = t('my_account.phone.message.error_phone')
    } else {
      dispatch(resetPhoneState())
    }

    formik.setFieldError('phone', error)
    return error
  }

  const validateCode = (code: string) => {
    let error

    if (!validatePhoneValidationCode(code)) {
      error = t('my_account.phone.message.error_code')
    }

    formik.setFieldError('code', error)
    return error
  }

  const handleReqCodeClick = () => {
    if (!formik.dirty || formik.errors.phone) {
      return
    }

    if (phoneInfo.isCodeSent && !isEnd) {
      bridge.openToast({ toast: { body: t('common.retry_auth_forbidden') } })
      return
    }

    phoneInfo.isCodeSent && (timerRef.current as any).startTimer(60 * 5)
    dispatch(enhancedReqPhoneCode({ phone: formik.values.phone }))
    triggerTimer(10)
  }

  // TODO: use after
  const handleSubmitButtonClick = () => {
    if (!phoneInfo.isCodeSent) {
      return
    }

    const error = validateCode(formik.values.code)

    if (error) {
      return
    }

    setModalOpen()
  }

  const handleChangePhoneClick = () => {
    dispatch(enhancedReqUpdatePhoneNumber({ phone: formik.values.phone, code: formik.values.code }))
  }

  const handlePhoneChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (phoneInfo.isCodeSent) {
      triggerTimer(0)
    }

    const revertedPhoneNumber = revertPhoneNumber(e.target.value)

    validatePhone(revertedPhoneNumber)

    if (country === 'KR' || country === 'JP' || country === 'GB') {
      revertedPhoneNumber.length === 11
        ? formik.setFieldValue('phone', revertedPhoneNumber)
        : formik.setFieldValue('phone', revertedPhoneNumber.slice(0, 11))
      return
    }

    if (country === 'US' || country === 'CA') {
      revertedPhoneNumber.length === 10
        ? formik.setFieldValue('phone', revertedPhoneNumber)
        : formik.setFieldValue('phone', revertedPhoneNumber.slice(0, 10))
      return
    }

    formik.setFieldValue('phone', revertedPhoneNumber)
  }

  return (
    <AppScreen
      appBar={{
        title: t('my_account.index.menu.edit_phone_number'),
        backButton: {
          renderIcon: () => <IconBack />,
          onClick: () => {},
        },
      }}>
      <CustomPageContainer
        height={keyboardActivateStatus === 'by-top' && translateY === 0 ? viewportHeight : -1}
        os={(props as { os: 'Android' | 'Cupertino' }).os}>
        <Container
          height={keyboardActivateStatus === 'by-top' && translateY === 0 ? viewportHeight : -1}
          os={(props as { os: 'Android' | 'Cupertino' }).os}>
          <TitleMessage>{t('my_account.phone.message.title')}</TitleMessage>
          <HelperMessage>
            {t('my_account.phone.message.helper1', { phone: formatPhoneNumber(phoneInfo.phone, country) })}
          </HelperMessage>

          <form onSubmit={formik.handleSubmit}>
            <InputField
              ref={phoneRef}
              name="phone"
              type="tel"
              placeholder={t('my_account.phone.placeholder.phone')}
              onChange={handlePhoneChange}
              value={formatPhoneNumber(formik.values.phone, country)}
              invalid={!!phoneInfo.phoneCodeMessage}
              disabled={phoneInfo.isCodeSent}
            />
            <ErrorMessage>
              {phoneInfo.phoneCodeErrorCode ? phoneInfoErrorMessage() : phoneInfo.phoneCodeMessage}
            </ErrorMessage>
            {phoneInfo.isCodeSent && (
              <SubmitButton
                onClick={handleReqCodeClick}
                disabled={!formik.dirty || !!formik.errors.phone}
                pending={phoneInfo.isCodeSent}>
                {phoneInfo.isCodeSent ? (
                  <>
                    {t('my_account.phone.button.retry_req_code')} &nbsp;
                    <span>(</span>
                    <Timer ref={timerRef} initialDuration={60 * 5} />
                    <span>)</span>
                  </>
                ) : (
                  t('my_account.phone.button.req_code')
                )}
              </SubmitButton>
            )}

            <InputField
              display={phoneInfo.isCodeSent ? 'initial' : 'none'}
              name="code"
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              placeholder={t('my_account.phone.placeholder.code')}
              onChange={formik.handleChange}
              value={formik.values.code}
              ref={codeRef}
              invalid={!!(formik.errors.code || phoneInfo.updatePhoneMessage)}
            />
            {phoneInfo.isCodeSent && (
              <>
                {!(formik.errors.code || phoneInfo.updatePhoneMessage) ? (
                  <HelperMessage>{t('my_account.phone.message.helper2')}</HelperMessage>
                ) : (
                  <ErrorMessage>
                    {formik.errors.code ||
                      (phoneInfo.updatePhoneErrorCode && t(`hoian.errors.${phoneInfo.updatePhoneErrorCode}`)) ||
                      phoneInfo.updatePhoneMessage}
                  </ErrorMessage>
                )}
              </>
            )}
          </form>
          {country === 'KR' && (
            <PayWarnMessage>
              <PayWarningWrapper>
                <StrongText>{t('my_account.phone.message.pay_guide1')}</StrongText>
                <Text>{t('my_account.phone.message.pay_guide2')}</Text>
              </PayWarningWrapper>
              <div
                onClick={() => {
                  window.location.href = `${SCHEME_PREFIX}://web/wv/faqs/2481?present=top`
                }}>
                <ChevronRight />
              </div>
            </PayWarnMessage>
          )}
        </Container>
        {phoneInfo.isCodeSent ? (
          <BottomButton
            os={(props as { os: 'Android' | 'Cupertino' }).os}
            isKeyboardActivated={keyboardActivateStatus !== 'idle'}
            translateY={translateY}
            onClick={handleSubmitButtonClick}
            disabled={!formik.values.code}>
            {t('my_account.phone.button.update_phone')}
          </BottomButton>
        ) : (
          <BottomButton
            os={(props as { os: 'Android' | 'Cupertino' }).os}
            isKeyboardActivated={keyboardActivateStatus !== 'idle'}
            translateY={translateY}
            onClick={handleReqCodeClick}
            disabled={!formik.dirty || !!formik.errors.phone}
            pending={phoneInfo.isCodeSent}>
            {t('my_account.phone.button.req_code')}
          </BottomButton>
        )}

        <FullSizeModal isOpen={isModalOpen} onClose={setModalClose} bodyStyle={ModalBodyStyle}>
          <ModalTitle>
            {t('my_account.phone.title.modal_title', { phone: formatPhoneNumber(formik.values.phone, country) })}
          </ModalTitle>
          <ModalBody textAlign="initial">{t('my_account.phone.message.modal_body')}</ModalBody>
          <ModalButtonContainer>
            <ModalButton onClick={setModalClose}>{t('common.cancel_modal')}</ModalButton>
            <ModalButton
              active={true}
              onClick={() => {
                setModalClose(() => {
                  handleChangePhoneClick()
                })
              }}>
              {t('common.confirm_modal')}
            </ModalButton>
          </ModalButtonContainer>
        </FullSizeModal>

        {(codeAsyncState.pending || phoneAsyncState.pending) && <Loader />}
      </CustomPageContainer>
    </AppScreen>
  )
}

export default withOS(PhonePage)

const CustomPageContainer = styled.div<{ height?: number; os?: 'Android' | 'Cupertino' }>`
  height: ${(props) => {
    // iOS 11.2 버전 이상: env(safe-area-inset-top)

    // android 에서는 safe-area 관련 css 명세가 없다.
    if (props.os === 'Android') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM})`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 없을 때 safe-area 의 상하 영역을 제거한 영역만큼만 height 로 설정한다.
    if (props.height === -1 && props.os === 'Cupertino') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM} - env(safe-area-inset-bottom) - env(safe-area-inset-top))`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 있을 때 safe-area 의 상단 영역을 제거한 영역만큼만 height 로 설정한다.
    return `calc(${props.height}px - ${NAVBAR_HEIGHT_REM} + ${__DANGEROUS_COVER_UNKNOWN_MARGIN_PX} - env(safe-area-inset-top))`
  }};
  height: ${(props) => {
    // iOS 11.0 버전 이하 fallback: constant(safe-area-inset-top)

    // android 에서는 safe-area 관련 css 명세가 없다.
    if (props.os === 'Android') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM})`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 없을 때 safe-area 의 상하 영역을 제거한 영역만큼만 height 로 설정한다.
    if (props.height === -1 && props.os === 'Cupertino') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM} - constant(safe-area-inset-bottom) - constant(safe-area-inset-top))`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 있을 때 safe-area 의 상단 영역을 제거한 영역만큼만 height 로 설정한다.
    return `calc(${props.height}px - ${NAVBAR_HEIGHT_REM} + ${__DANGEROUS_COVER_UNKNOWN_MARGIN_PX} - constant(safe-area-inset-top))`
  }};
`

const Container = styled.div<{ height?: number; os?: 'Android' | 'Cupertino' }>`
  padding: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
  height: ${(props) => {
    // iOS 11.2 버전 이상: env(safe-area-inset-top)

    // android 에서는 safe-area 관련 css 명세가 없다.
    if (props.os === 'Android') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM} - ${BOTTOM_BUTTON_HEIGHT_PX})`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 없을 때 safe-area 의 상하 영역을 제거한 영역만큼만 height 로 설정한다.
    if (props.height === -1 && props.os === 'Cupertino') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM} - ${BOTTOM_BUTTON_HEIGHT_PX} - env(safe-area-inset-bottom) - env(safe-area-inset-top))`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 있을 때 safe-area 의 상단 영역을 제거한 영역만큼만 height 로 설정한다.
    return `calc(${props.height}px - ${NAVBAR_HEIGHT_REM} - ${BOTTOM_BUTTON_HEIGHT_PX} + ${__DANGEROUS_COVER_UNKNOWN_MARGIN_PX} - env(safe-area-inset-top))`
  }};
  height: ${(props) => {
    // iOS 11.0 버전 이하 fallback: constant(safe-area-inset-top)

    // android 에서는 safe-area 관련 css 명세가 없다.
    if (props.os === 'Android') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM} - ${BOTTOM_BUTTON_HEIGHT_PX})`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 없을 때 safe-area 의 상하 영역을 제거한 영역만큼만 height 로 설정한다.
    if (props.height === -1 && props.os === 'Cupertino') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM} - ${BOTTOM_BUTTON_HEIGHT_PX} - constant(safe-area-inset-bottom) - constant(safe-area-inset-top))`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 있을 때 safe-area 의 상단 영역을 제거한 영역만큼만 height 로 설정한다.
    return `calc(${props.height}px - ${NAVBAR_HEIGHT_REM} - ${BOTTOM_BUTTON_HEIGHT_PX} + ${__DANGEROUS_COVER_UNKNOWN_MARGIN_PX} - constant(safe-area-inset-top))`
  }};
`

const TitleMessage = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
  color: ${vars.$scale.color.gray900};
  margin-top: 24px;
`

const HelperMessage = styled.div`
  margin: 8px 0 0;
  font-size: 0.8125rem;
  color: ${vars.$scale.color.gray600};
`

const InputField = styled.input<{ invalid?: boolean; display?: 'none' | 'initial' }>`
  background: transparent;
  margin: 24px 0 0;
  padding: 12px 16px;
  border: 1px solid ${vars.$scale.color.gray400};
  border-radius: 6px;
  width: 100%;
  font-size: 1rem;
  display: ${(props) => props.display ?? 'initial'};

  ::placeholder {
    color: ${vars.$scale.color.gray500};
  }

  &:focus {
    border-color: ${vars.$scale.color.gray900};
  }

  ${(props) =>
    props.invalid &&
    css`
      border-color: ${vars.$scale.color.red800};
    `}
`

const ErrorMessage = styled.div`
  margin: 8px 0 0;
  font-size: 0.8125rem;
  color: ${vars.$scale.color.red800};
`

const PayWarnMessage = styled.div`
  border-radius: 10px;
  background: ${vars.$scale.color.gray100};
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px 18px 18px;
`

const SubmitButton = styled.div<{ disabled?: boolean; pending?: boolean }>`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border: ${(props) => (props.pending ? `1px solid ${vars.$scale.color.gray300}` : 'none')};
  background-color: ${(props) =>
    props.pending ? 'transparent' : props.disabled ? vars.$scale.color.gray300 : vars.$scale.color.carrot500};
  color: ${(props) =>
    props.disabled
      ? vars.$scale.color.gray500
      : props.pending
      ? vars.$scale.color.gray900
      : vars.$static.color.staticWhite};
  text-align: center;
  border-radius: 6px;
  margin-top: 16px;
`

const StrongText = styled.span`
  ${subtitle2};

  margin-right: 0.5rem;
`

const Text = styled.span`
  ${body2}
`

const PayWarningWrapper = styled.div`
  margin-right: 20px;
`

const BottomButton = styled.div<{
  disabled?: boolean
  pending?: boolean
  translateY?: number
  isKeyboardActivated?: boolean
  os?: 'Android' | 'Cupertino'
}>`
  position: ${(props) => (props.os === 'Android' ? 'relative' : 'absolute')};
  width: ${(props) =>
    props.isKeyboardActivated
      ? '100%'
      : `calc(100% - ${BOTTOM_BUTTON_HORIZONTAL_MARGIN_PX} - ${BOTTOM_BUTTON_HORIZONTAL_MARGIN_PX})`};
  border-radius: ${(props) => (props.isKeyboardActivated ? 'none' : '6px')};
  left: ${(props) => (props.isKeyboardActivated ? '0' : BOTTOM_BUTTON_HORIZONTAL_MARGIN_PX)};
  bottom: ${(props) => (props.os === 'Android' && !props.isKeyboardActivated ? '12px' : 'none')};
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border: ${(props) => (props.pending ? `1px solid ${vars.$scale.color.gray500}` : 'none')};
  background-color: ${(props) => (props.disabled ? vars.$scale.color.gray300 : vars.$scale.color.carrot500)};
  color: ${(props) =>
    props.disabled
      ? vars.$scale.color.gray500
      : props.pending
      ? vars.$scale.color.gray900
      : vars.$static.color.staticWhite};
  text-align: center;
  transform: ${(props) => `translateY(-${props.translateY}px)`};
`
