import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { vars } from '@seed-design/design-token'
import { AppScreen } from '@stackflow/plugin-basic-ui'

import { PageContainer } from '@src/components/base/Container'
import { Select } from '@src/components/base/Select'
import { reqCheckinRegions } from '@src/ducks/user'
import { reqArticleRegions, reqUpdateArticlesRegion } from '@src/ducks/article'
import { checkinRegionsSelector } from '@src/selectors/user'
import { articleRegionsSelector } from '@src/selectors/article'
import { SCHEME_PREFIX } from '@src/config'
import { ModalBodyStyle, ModalBody, ModalButtonContainer, ModalButton } from '@src/components/base/Modal/Modal'
import { useActionState } from '@src/hooks/useActionState'
import Loader from '@src/components/base/Loader'
import { useDispatchWithHistory } from '@src/hooks/useDispatchWithHistory'
import FullSizeModal from '@src/components/base/Modal/FullSizeModal'
import useModalState from '@src/hooks/useModalState'
import ArrowDown from '@src/components/svg/ArrowDown'
import IconBack from '@src/components/svg/IconBack'
import { RegionInfo } from '@src/types/common'

interface FormValues {
  articleRegionJsonString: string | null
  checkinRegionJsonString: string | null
}

const ChangeRegionPage: React.FC = () => {
  const dispatch = useDispatchWithHistory()
  const { t } = useTranslation()
  const articleRegions = useSelector(articleRegionsSelector)
  const checkinRegions = useSelector(checkinRegionsSelector)

  const formik = useFormik<FormValues>({
    initialValues: { articleRegionJsonString: null, checkinRegionJsonString: null },
    onSubmit: () => {},
    validateOnChange: false,
  })

  const [isModalOpen, setModalOpen, setModalClose] = useModalState(false)
  const [enhancedReqArticleRegions, { pending: articleRegionsPending }] = useActionState(reqArticleRegions)
  const [enhancedReqCheckinRegions, { pending: checkinRegionsPending }] = useActionState(reqCheckinRegions)
  const [enhancedReqUpdateArticleRegion, { pending: updateArticleRegionPending }] =
    useActionState(reqUpdateArticlesRegion)

  useEffect(() => {
    dispatch(enhancedReqArticleRegions())
    dispatch(enhancedReqCheckinRegions())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateArticleRegionId = () => {
    let error

    if (!formik.values.articleRegionJsonString) {
      error = t('other_settings.change_articles_region.message.error_select_region')
    }

    formik.setFieldError('articleRegionId', error)
    return error
  }

  const validateCheckinRegionId = () => {
    let error

    if (!formik.values.checkinRegionJsonString) {
      error = t('other_settings.change_articles_region.message.error_select_region')
    }

    formik.setFieldError('checkinRegionId', error)
    return error
  }

  const checkIfSameRegion = () => {
    let error

    const selectedArticleRegion: RegionInfo = JSON.parse(formik.values.articleRegionJsonString)
    const selectedCheckinRegion: RegionInfo = JSON.parse(formik.values.checkinRegionJsonString)

    if (selectedArticleRegion.locationNameId) {
      // h3 user
      if (selectedArticleRegion.locationNameId === selectedCheckinRegion.locationNameId) {
        error = t('other_settings.change_articles_region.message.error_same_region')
      }
    } else if (selectedArticleRegion.regionId === selectedCheckinRegion.regionId) {
      // region user
      error = t('other_settings.change_articles_region.message.error_same_region')
    }

    formik.setFieldError('checkinRegionId', error)
    return error
  }

  const handleSubmitButtonClick = () => {
    if (validateArticleRegionId() || validateCheckinRegionId() || checkIfSameRegion()) {
      return
    }

    setModalOpen()
  }

  const handleUpdateArticlesRegion = () => {
    const selectedArticleRegion: RegionInfo = JSON.parse(formik.values.articleRegionJsonString)
    const selectedCheckinRegion: RegionInfo = JSON.parse(formik.values.checkinRegionJsonString)

    dispatch(enhancedReqUpdateArticleRegion({ selectedArticleRegion, selectedCheckinRegion }))
  }

  return (
    <AppScreen
      appBar={{
        title: t('other_settings.index.menu.change_articles_region.title'),
        backButton: {
          renderIcon: () => <IconBack />,
          onClick: () => {},
        },
      }}>
      <PageContainer>
        <Container>
          <Description>{t('other_settings.change_articles_region.message.description')}</Description>
          <Label>{t('other_settings.change_articles_region.label.previous')}</Label>
          <SelectWrapper>
            <Select
              name="articleRegionJsonString"
              value={formik.values.articleRegionJsonString || ''}
              onChange={formik.handleChange}>
              <option value="">{t('other_settings.change_articles_region.placeholder.previous')}</option>
              {!!articleRegions &&
                articleRegions.map((articleRegion: RegionInfo, index: number) => {
                  const articleRegionString = JSON.stringify(articleRegion)

                  return (
                    <option key={articleRegionString + index} value={articleRegionString}>
                      {articleRegion.displayName}
                    </option>
                  )
                })}
            </Select>
            <ArrowDown />
          </SelectWrapper>
          <ErrorMessage>{formik.errors.articleRegionId}</ErrorMessage>

          <Label>{t('other_settings.change_articles_region.label.next')}</Label>
          <SelectWrapper>
            <Select
              name="checkinRegionJsonString"
              value={formik.values.checkinRegionJsonString || ''}
              onChange={formik.handleChange}>
              <option value="">{t('other_settings.change_articles_region.placeholder.next')}</option>
              {!!checkinRegions &&
                checkinRegions.map((checkinRegion: RegionInfo, index: number) => {
                  const checkinRegionString = JSON.stringify(checkinRegion)

                  return (
                    <option key={checkinRegionString + index} value={checkinRegionString}>
                      {checkinRegion.displayName}
                    </option>
                  )
                })}
            </Select>
            <ArrowDown />
          </SelectWrapper>
          <ErrorMessage>{formik.errors.checkinRegionId}</ErrorMessage>

          <SubmitButton onClick={handleSubmitButtonClick}>
            {t('other_settings.change_articles_region.button.update')}
          </SubmitButton>
          <HelperMessage>
            <Trans i18nKey="other_settings.change_articles_region.message.helper">
              더 궁금한 점이 있으면
              {/* Todo: IOS 버그 있음 해당이슈 해결된 버전이 배포된 후 변경 필요 https://daangn.slack.com/archives/CUV91G2MT/p1611214601000800 */}
              <Link href={`${SCHEME_PREFIX}://web/wv/faqs?present=top`}>고객센터</Link>로 문의주세요
            </Trans>
          </HelperMessage>
        </Container>

        <FullSizeModal isOpen={isModalOpen} onClose={setModalClose} bodyStyle={ModalBodyStyle}>
          <ModalBody>{t('other_settings.change_articles_region.message.modal_body')}</ModalBody>
          <ModalButtonContainer>
            <ModalButton onClick={setModalClose}>{t('common.cancel_modal')}</ModalButton>
            <ModalButton
              active={true}
              onClick={() => {
                setModalClose(() => {
                  handleUpdateArticlesRegion()
                })
              }}>
              {t('common.confirm_modal')}
            </ModalButton>
          </ModalButtonContainer>
        </FullSizeModal>

        {(updateArticleRegionPending || articleRegionsPending || checkinRegionsPending) && <Loader />}
      </PageContainer>
    </AppScreen>
  )
}

export default ChangeRegionPage

const Container = styled.div`
  padding: 16px;
`

const Description = styled.div`
  font-size: 1rem;
`

const Label = styled.div`
  margin: 24px 0 12px;
  font-size: 0.875rem;
  color: ${vars.$scale.color.gray600};
`

const SelectWrapper = styled.div`
  position: relative;
`

const ErrorMessage = styled.div`
  margin: 8px 0 0;
  font-size: 0.8125rem;
  color: ${vars.$scale.color.red800};
`

const SubmitButton = styled.div<{ disabled?: boolean; pending?: boolean }>`
  display: block;
  margin: 32px 0 0;
  padding: 10.5px 0;
  border-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  background-color: ${(props) =>
    props.pending
      ? vars.$scale.color.gray700
      : props.disabled
      ? vars.$scale.color.gray300
      : vars.$scale.color.carrot500};
  color: ${vars.$static.color.staticWhite};
  text-align: center;
`

const HelperMessage = styled.div`
  margin: 32px 0 0;
  font-size: 0.875rem;
  color: ${vars.$scale.color.gray900};
  text-align: center;
`

const Link = styled.a`
  color: ${vars.$scale.color.carrot500};
  text-decoration: underline;
`
