import { createAction, createReducer } from '@reduxjs/toolkit'

import { createHistoryAction } from '@src/utils/action'

export const reqPhoneCode = createAction<{ phone: string }>('REQ_PHONE_CODE')
export const resPhoneCode = createAction<{ message: string | null; code: string | null }>('RES_PHONE_CODE')
export const reqUpdatePhoneNumber = createHistoryAction<{ phone: string; code: string }>('REQ_UPDATE_PHONE_NUMBER')
export const resUpdatePhoneNumber =
  createAction<{ message: string | null; code: string | null }>('RES_UPDATE_PHONE_NUMBER')
export const resetPhoneState = createAction('RESET_PHONE_STATE')

export interface State {
  phoneCodeMessage: string | null
  phoneCodeErrorCode: string | null
  isCodeSent: boolean
  updatePhoneMessage: string | null
  updatePhoneErrorCode: string | null
}

export const INITIAL_STATE: State = {
  phoneCodeMessage: null,
  phoneCodeErrorCode: null,
  isCodeSent: false,
  updatePhoneMessage: null,
  updatePhoneErrorCode: null,
}

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(reqPhoneCode, (state) => {
      state.phoneCodeMessage = null
    })
    .addCase(resPhoneCode, (state, { payload }) => {
      state.phoneCodeMessage = payload.message
      state.phoneCodeErrorCode = payload.code

      if (payload.message === null) {
        state.isCodeSent = true
      }
    })
    .addCase(reqUpdatePhoneNumber, (state) => {
      state.updatePhoneMessage = null
    })
    .addCase(resUpdatePhoneNumber, (state, { payload }) => {
      state.updatePhoneMessage = payload.message
      state.updatePhoneErrorCode = payload.code
    })
    .addCase(resetPhoneState, (state) => {
      state.isCodeSent = false
      state.phoneCodeMessage = null
      state.phoneCodeErrorCode = null
      state.updatePhoneMessage = null
      state.updatePhoneErrorCode = null
    })
})
