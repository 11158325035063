import { useMemo } from 'react'

import { bridge } from '@src/bridge'

export const useEnvironment = (): 'Android' | 'Cupertino' => {
  const envrionment = useMemo(() => {
    switch (bridge.driver.getCurrentEnvironment()) {
      case 'Web':
        return 'Android'
      default:
        return bridge.driver.getCurrentEnvironment()
    }
  }, [])

  return envrionment as 'Android' | 'Cupertino'
}
