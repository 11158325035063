import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

export const Select = styled.select`
  display: block;
  padding: 12px 16px;
  width: 100%;
  border: 1px solid ${vars.$scale.color.gray400};
  border-radius: 6px;
  font-size: 1rem;
  color: ${vars.$scale.color.gray900};
  background: transparent;
  appearance: none;
`
